/* eslint-disable eqeqeq */
import { Box } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import packageProfile from "../../../assets/images/Frame.svg";

import { useEffect } from "react";
import { Button } from "reactstrap";
import Loader from "../../../components/Atoms/Loader";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { cancelExam } from "../../../store/GMAT/Analytical/action";
import { getVerbalResult } from "../../../store/GMAT/Verbal/action";
import "../ResultPage.scss";
import packageImage from "../img/01-3.png";
// import ViewListeningResult from "./ViewListening/ViewListening";

// const score = [
//   9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1, 0,
// ];
const imageHelper = (data) => {
  switch (data?.toLowerCase()?.trim()) {
    case "reading":
      return "reading.svg";
    case "speaking":
      return "speaking.svg";
    case "writing":
      return "writing.svg";
    case "listening":
      return "listening.svg";
    default:
      return "reading.svg";
  }
};

const VerResult = ({ history }) => {
  const { id, packageId, type } = useParams();
  const [detailShow, setDetailShow] = useState(false);
  const dispatch = useDispatch();
  const {
    authtoken,
    verbalResult,
    verbalResultLoading,
    QuantitativeExamPackage,
    singlePackage,
    profile,
  } = useSelector((state) => ({
    authtoken: state.Login.token,

    singlePackage: state.PackageReducer.singlePackage?.singlePackage,
    verbalResult: state.verbalQuestionReducer.verbalResult,
    verbalResultLoading: state.verbalQuestionReducer.verbalResultLoading,
    profile: state.Profile?.data,
  }));

  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return packageImage;
  };
  const getPhotoUrl1 = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return packageProfile;
  };
  const ExamId = localStorage.getItem("verBalExamId");
  useEffect(() => {
    if (ExamId) {
      dispatch(getVerbalResult(authtoken, ExamId));
    }
  }, []);
  useEffect(() => {
    dispatch(cancelExam(authtoken));
  }, []);
  let verbal = {
    51: 99,
    50: 99,
    49: 99,
    48: 99,
    47: 99,
    46: 99,
    45: 99,
    44: 98,
    43: 98,
    42: 96,
    41: 93,
    40: 90,
    39: 88,
    38: 84,
    37: 81,
    36: 79,
    35: 74,
    34: 69,
    33: 67,
    32: 64,
    31: 59,
    30: 56,
    29: 54,
    28: 49,
    27: 44,
    26: 41,
    25: 37,
    24: 34,
    23: 30,
    22: 28,
    21: 24,
    20: 21,
    19: 17,
    18: 16,
    17: 13,
    16: 11,
    15: 9,
    14: 8,
    13: 6,
    12: 4,
    11: 3,
    10: 2,
    9: 2,
    8: 1,
    7: 1,
    6: 0,
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  };
  const gmatPackageId = localStorage.getItem("gmatPackageId");
  return (
    <React.Fragment>
      <div className="result-main-area">
        <Container className="g-0">
          <div className="result-inner-area">
            {/* <button onClick={() => scroll('target1')}>go</button> */}
            <h3>
              <span onClick={() => history.goBack()}>
                <i className="bx bx-arrow-back"></i>
              </span>
              Your Result
            </h3>
            {verbalResultLoading ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="result-main-area-inner-box">
                <React.Fragment>
                  <h4>Verbal exam result</h4>
                  <div className="course-result--profile-area first-result-box">
                    <Row>
                      <Col lg={3}>
                        <div
                          className="exem-course-result-package rounded-5 m-0"
                          style={{
                            boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.08)",
                            overflow: "hidden",
                          }}
                        >
                          <Card className="course-result--profile-box rounded-5 m-0">
                            <div
                              className="card-img-top"
                              style={{
                                backgroundImage: `url(${getPhotoUrl(
                                  singlePackage?.photo
                                )})`,
                              }}
                            ></div>

                            <Card.Body
                              style={{
                                borderRadius: "0 0 10px 10px",
                                overflow: "hidden",
                              }}
                            >
                              <div className="course-review-area-exam-page d-flex">
                                <Box
                                  component="fieldset"
                                  borderColor="transparent"
                                >
                                  <span>
                                    <Rating
                                      name="simple-controlled"
                                      precision={0.1}
                                      defaultValue={
                                        singlePackage?.aveRatings || 0
                                      }
                                      readOnly
                                    />
                                  </span>
                                </Box>
                                <span style={{ color: "black" }}></span>
                              </div>
                              <Card.Title
                                className="course-review-area-exam-course-title"
                                style={{
                                  fontSize: "18px",
                                }}
                              >
                                {singlePackage?.packageName}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </div>
                      </Col>
                      <Col lg={9}>
                        <div className="exam-result-profile-feedback-view ps-3">
                          <div className="exam-result-profile---content">
                            <img
                              className="rounded-circle result-profile-page"
                              src={getPhotoUrl1(
                                profile?.getData?.getProfile?.photo
                              )}
                              alt=""
                            />

                            <span>
                              {profile?.getData?.getProfile?.fullName}
                            </span>
                          </div>
                          <div
                            className={
                              type === "writing"
                                ? "d-flex justify-content-center text-center gap-5 border-bottom py-4"
                                : "exam-result-feedback---content"
                            }
                          >
                            {type === "writing" ? null : (
                              <div className="exam-result-feedback---item">
                                <p>Mark</p>
                                <h6>
                                  {verbalResult?.mark?.toFixed(2)}
                                  /51
                                </h6>
                              </div>
                            )}
                            <div className="exam-result-feedback---item">
                              <p>Percentile</p>
                              <h6>{verbal[Math.ceil(verbalResult?.mark)]}%</h6>
                            </div>
                            <div className="exam-result-feedback---item">
                              {/* <p>Time Spent</p> */}
                              {/* <h6>{results?.timeTaken || 0} min</h6> */}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="exam-ques-your-position">
                          {/* <p>Review Brand Score</p>
                      <ul>
                        {score.map((list, idx, item) => {
                          return (
                            <li
                              key={idx}
                              className={
                                handleActiveClass(list, idx, item) === list
                                  ? 'your-exam-answer'
                                  : ''
                              }
                            >
                              {list}
                            </li>
                          );
                        })}
                      </ul> */}
                        </div>
                      </Col>
                    </Row>
                    <div className="pt-2 d-flex align-items-center">
                      {/* <img
                        src={require(`../img/${imageHelper(type)}`).default}
                        alt="set"
                        width={"40px"}
                      /> */}
                      {/* Set : <strong>{results?.setId?.setName}</strong> */}
                    </div>
                  </div>
                  <h4>Your answers keys</h4>
                </React.Fragment>

                <div>
                  {verbalResultLoading ? (
                    <div className="text-center">
                      <Loader />
                    </div>
                  ) : (
                    <div className="course-result--profile-area">
                      <Table borderless hover responsive="md">
                        <thead>
                          <tr className="text-center">
                            <th>
                              <span>Question no</span>
                            </th>
                            <th>
                              <span>Right answer</span>
                            </th>
                            <th>
                              <span>Your answer</span>
                            </th>
                            <th>
                              <span>Status</span>
                            </th>
                            <th>
                              <span>Level</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {verbalResult?.answer?.map((ans, idx) => (
                            <tr>
                              <td>
                                <span className="ques-id-serial text-center">
                                  {ans?.serial}
                                </span>
                              </td>
                              <td>{ans?.rightAnswer[0]}</td>
                              <td>{ans?.answer}</td>
                              <td>
                                {ans?.difficultyLevel === 1
                                  ? "Easy"
                                  : ans?.difficultyLevel === 2
                                  ? "Medium"
                                  : "Hard"}
                              </td>
                              <td>
                                {" "}
                                {ans.mark === 1 ? (
                                  <span className="correct-ans">
                                    <i className="bx bx-check"></i>
                                  </span>
                                ) : (
                                  <span className="false-ans">
                                    <i className="bx bx-x"></i>
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  <div
                    className="text-center"
                    onClick={() =>
                      history.push(`/gmat-service/${gmatPackageId}`)
                    }
                  >
                    <Button color="primary" outline>
                      Go to package
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(VerResult);
