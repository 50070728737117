import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getQuantitativeExamPackage,
  getQuantitativeQues,
  storeQuantitativeData,
} from "../../../store/GMAT/Quantitative/action";
import QuanFooter from "./QuanFooter";
import QuantitativeQuesList from "./QuantitativeQuesList";

const QuantitativeDS = () => {
  const dispatch = useDispatch();
  const [object, setObject] = useState({});
  const { id } = useParams();

  //   const { assessmentId, examType, currentIndex } = useParams();
  const {
    authtoken,
    QuantitativeQues,
    QuantitativeQuesLoading,
    currentIndex,
    QuantitativeExamPackage,
    ans,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    QuantitativeQues: state.QuantitativeQuestionReducer.QuantitativeQues,
    currentIndex: state.QuantitativeQuestionReducer.currentIndex,
    QuantitativeQuesLoading:
      state.QuantitativeQuestionReducer.QuantitativeQuesLoading,
    ans: state.QuantitativeQuestionReducer.ans,
    currentIndex: state.QuantitativeQuestionReducer.currentIndex,
    QuantitativeExamPackage:
      state.QuantitativeQuestionReducer.QuantitativeExamPackage,
  }));
  console.log("QuantitativeExamPackage", QuantitativeExamPackage);
  useEffect(() => {
    if (id) {
      dispatch(getQuantitativeQues(authtoken, 1, 3, id));
    }
  }, []);
  useEffect(() => {
    dispatch(getQuantitativeExamPackage(authtoken, id));
  }, []);
  localStorage.setItem("quanExamId", QuantitativeExamPackage?.values);

  //   useEffect(() => {
  //     const debounce = setTimeout(() => {
  //       if (object?.answer) {
  //         submitData(object);
  //         setObject({});
  //       }
  //     }, 300);
  //     return () => clearTimeout(debounce);

  //   }, [object?.answer]);

  const handleStoreAnswer = ({ e, data, number }) => {
    console.log("e", e.target.value);
    console.log("data", data);
    const { value } = e.target;
    console.log("data", data);
    const serial = number - 1;
    const body = {
      serial: number,
      answer: value,
      difficultyLevel: data?.difficultyLevel,
    };
    dispatch(storeQuantitativeData("singleAns", body));
  };

  return (
    <React.Fragment>
      <div className="ques-right-part-container">
        <QuantitativeQuesList
          handleChangeMcq={handleStoreAnswer}
          questions={QuantitativeQues}
          questionNumber={currentIndex + 1}
        />
      </div>
      <QuanFooter packageId={id} />
    </React.Fragment>
  );
};

export default QuantitativeDS;
