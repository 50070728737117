import React from "react";

const IrIcon = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3 5.48438C3.08256 5.48438 2.90625 5.66069 2.90625 5.87813C2.90625 6.09556 3.08256 6.27188 3.3 6.27188H7.52188C7.73931 6.27188 7.91563 6.09556 7.91563 5.87813C7.91563 5.66069 7.73931 5.48438 7.52188 5.48438H3.3Z"
        fill="#0052CC"
      />
      <path
        d="M5.19831 7.71973H3.3C3.08256 7.71973 2.90625 7.89604 2.90625 8.11348C2.90625 8.33091 3.08256 8.50723 3.3 8.50723H5.19831C5.41575 8.50723 5.59206 8.33091 5.59206 8.11348C5.59206 7.89604 5.41575 7.71973 5.19831 7.71973Z"
        fill="#0052CC"
      />
      <path
        d="M5.14044 12.7314H2.14356C1.49913 12.7314 0.975438 12.2056 0.975438 11.5589V3.58332C0.975438 2.9367 1.49913 2.41082 2.14356 2.41082H2.95687V2.5097C2.95687 3.08413 3.42412 3.55095 3.99856 3.55095H7.76106C8.3355 3.55095 8.80275 3.08413 8.80275 2.5097V2.41082H9.61213C10.2671 2.41082 10.7798 2.92576 10.7798 3.58332V5.82769C10.7798 6.04513 10.9561 6.22145 11.1736 6.22145C11.391 6.22145 11.5673 6.04513 11.5673 5.82769V3.58332C11.5673 2.5027 10.6901 1.62332 9.61169 1.62332H8.80231V1.5227C8.80231 0.948258 8.33506 0.481445 7.76063 0.481445H3.99813C3.42369 0.481445 2.95644 0.948258 2.95644 1.5227V1.62332H2.14356C1.06513 1.62332 0.1875 2.5027 0.1875 3.58332V11.5589C0.1875 12.6396 1.06513 13.5189 2.14356 13.5189H5.14044C5.35787 13.5189 5.53419 13.3426 5.53419 13.1252C5.53419 12.9078 5.35787 12.7314 5.14044 12.7314ZM3.74481 1.5227C3.74481 1.3827 3.85856 1.26895 3.99856 1.26895H7.76106C7.90106 1.26895 8.01481 1.3827 8.01481 1.5227V2.50926C8.01481 2.64926 7.90106 2.76301 7.76106 2.76301H3.99856C3.85856 2.76301 3.74481 2.64926 3.74481 2.50926V1.5227Z"
        fill="#0052CC"
      />
      <path
        d="M12.3937 7.5942C11.8547 7.05476 10.9137 7.05432 10.3742 7.5942L6.88341 11.085C6.77098 11.1974 6.69748 11.3401 6.67079 11.4971L6.47916 12.6298C6.43848 12.8726 6.51854 13.122 6.69266 13.2957C6.8366 13.4396 7.03129 13.5184 7.23123 13.5184C7.27366 13.5184 7.31566 13.5149 7.35766 13.5079L8.48991 13.3167C8.64698 13.29 8.7896 13.2165 8.90204 13.1041L12.3929 9.61326C12.9498 9.05676 12.9498 8.15113 12.3937 7.5942ZM8.3591 12.5406L7.25616 12.7606L7.44079 11.6419L9.94285 9.13988L10.8489 10.0459L8.3591 12.5406ZM11.8364 9.05632L11.405 9.48857L10.4994 8.58295L10.9312 8.15113C11.0519 8.03038 11.2129 7.96345 11.3835 7.96345C11.555 7.96345 11.7156 8.02995 11.8364 8.15113C12.0862 8.40095 12.0862 8.80651 11.8364 9.05632Z"
        fill="#0052CC"
      />
    </svg>
  );
};

export default IrIcon;
