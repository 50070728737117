import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../components/Atoms/Loader";
import {
  getIRExamPackage,
  getIRQues,
  storeIRData,
  submitIRSingleAns,
} from "../../../store/GMAT/IR/action";
import GraphicalPage from "./Graphical/GraphicalPage";
import IrFooter from "./IrFooter";
import MultiSouce from "./MultiSource/MultiSouce";
import TableAnalysis from "./TableAnalysis/TableAnalysis";
import TwoPartAnalysis from "./TwoPartAnalysis/TwoPartAnalysis";

const IRtype = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [object, setObject] = useState({});
  const { id } = useParams();
  const {
    authToken,
    IRQues,
    IRQuesLoading,
    currentIndex,
    IRExamPackageLoading,
    IRExamPackage,
    tableAnalysisPartOne,
    tableAnalysisPartTwo,
    twoPartAnalysisOne,
    twoPartAnalysisTwo,
    GraphicalInterpretation,
    multiSource,
    section,
    answerResponse,
    currentLevel,
  } = useSelector((state) => ({
    authToken: state.Login.token,
    IRQues: state.IRQuesReducer.IRQues,
    currentIndex: state.IRQuesReducer.currentIndex,
    IRQuesLoading: state.IRQuesReducer.IRQuesLoading,
    IRExamPackage: state.IRQuesReducer.IRExamPackage,
    IRExamPackageLoading: state.IRQuesReducer.IRExamPackageLoading,
    tableAnalysisPartOne: state.IRQuesReducer.tableAnalysisPartOne,
    tableAnalysisPartTwo: state.IRQuesReducer.tableAnalysisPartTwo,
    twoPartAnalysisOne: state.IRQuesReducer.twoPartAnalysisOne,
    twoPartAnalysisTwo: state.IRQuesReducer.twoPartAnalysisTwo,
    GraphicalInterpretation: state.IRQuesReducer.GraphicalInterpretation,
    multiSource: state.IRQuesReducer.multiSource,
    section: state.IRQuesReducer.section,
    answerResponse: state.IRQuesReducer.answerResponse,
    currentLevel: state.IRQuesReducer.currentLevel,
  }));

  localStorage.setItem("IrExamId", IRExamPackage?.values);
  useEffect(() => {
    const option = {
      tableAnalysisPartOne,
      tableAnalysisPartTwo,
      twoPartAnalysisOne,
      twoPartAnalysisTwo,
      GraphicalInterpretation,
      multiSource,
      currentIndex,
      id,
      answerResponse,
      currentLevel,
    };
    dispatch(getIRQues(authToken, section, 3, "", option));
  }, []);
  useEffect(() => {
    dispatch(getIRExamPackage(authToken, id));
  }, []);
  const handleStoreAnswer = ({ e, questionInfo, number, option }) => {
    const { value } = e.target;
    console.log("length", questionInfo?.options?.length);

    const body = {
      serial: currentIndex,
      answer: value,
      difficultyLevel: questionInfo?.questionDifficulties,
      subQuestionId: option?._id,
      question: option?.option,
      questionId: questionInfo?._id,
      questionSerial: number,
      length: questionInfo?.options?.length,
    };

    dispatch(storeIRData("singleAns", body));
    setObject(body);
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (object?.answer) {
        submitData(object);
        setObject({});
      }
    }, 300);
    return () => clearTimeout(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object?.answer]);
  const submitData = (data) => {
    const { questionId } = data;
    const options = {
      history,
      examId: IRExamPackage?.values,
      questionId: questionId,
    };
    if (data) {
      dispatch(submitIRSingleAns(data, authToken, options));
    }
  };
  return (
    <div>
      <div>
        {IRQuesLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            {section === 1 || section === 2 ? (
              <TableAnalysis
                question={IRQues}
                handleChangeMcq={handleStoreAnswer}
                currentIndex={currentIndex}
              />
            ) : section === 3 || section === 4 ? (
              <TwoPartAnalysis
                question={IRQues}
                handleChangeMcq={handleStoreAnswer}
                currentIndex={currentIndex}
              />
            ) : section === 5 ? (
              <GraphicalPage
                question={IRQues}
                handleChangeMcq={handleStoreAnswer}
                currentIndex={currentIndex}
              />
            ) : (
              <MultiSouce
                question={IRQues}
                handleChangeMcq={handleStoreAnswer}
                currentIndex={currentIndex}
              />
            )}
          </>
        )}
        <IrFooter />
      </div>
    </div>
  );
};

export default IRtype;
