import React, { useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import Timer from "react-compound-timer/build";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useParams, withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import time from "../../../assets/images/Icon/clock.svg";
import keypad from "../../../assets/images/Icon/keypad.svg";
import papar from "../../../assets/images/Icon/paper-plane.svg";

import submit from "../../../assets/images/submit.svg";
import { toaster } from "../../../helpers/custom/Toast";
import {
  submitAnalyticalFullAns,
  submitAnalyticalSingleAns,
} from "../../../store/GMAT/Analytical/action";
// import submitPopup from "./img/popupIamge.png";

const AnalyticalFooter = ({ history, handleScroll }) => {
  const [timeValue, setTimeValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const { id } = useParams();
  const [startXam, setStartExam] = useState(false);
  // const [isRender, setIsRender] = useState(false);
  const dispatch = useDispatch();
  // const [isSubmit, setIsSubmit] = useState(false);
  const {
    currentIndex,
    totalProblemSolving,
    totalDataSufficiency,
    authtoken,
    singleAns,
    QuantitativeExamPackage,
    QuantitativeQues,
    AnalyticalExamPackage,
    AnalyticalQues,
    adding,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    currentIndex: state.QuantitativeQuestionReducer.currentIndex,
    totalProblemSolving: state.QuantitativeQuestionReducer.totalProblemSolving,
    totalDataSufficiency:
      state.QuantitativeQuestionReducer.totalDataSufficiency,
    QuantitativeExamPackage:
      state.QuantitativeQuestionReducer.QuantitativeExamPackage,
    singleAns: state.AnalyticalQuesReducer.singleAns,
    QuantitativeQues: state.QuantitativeQuestionReducer.QuantitativeQues,
    AnalyticalExamPackage: state.AnalyticalQuesReducer.AnalyticalExamPackage,
    AnalyticalQues: state.AnalyticalQuesReducer.AnalyticalQues,
    adding: state.AnalyticalQuesReducer.adding,
  }));

  console.log("sd", Object.keys(singleAns).length);

  const onOpenModal = () => setOpen(true);
  const onOpenModal2 = () => setOpen2(true);

  const [submitted, setSubmitted] = useState(false);

  useBeforeunload((event) => {
    // if (value !== '') {
    // console.log(`event`, event);
    event.preventDefault();
    // }
  });

  // window.addEventListener("beforeunload", function (e) {
  //   // Cancel the event and
  //   // show alert that the unsaved
  //   // changes would be lost
  //   e.preventDefault();
  //   e.returnValue = "";
  // });

  const checkpoints = [
    {
      time: 10000 * 60,
      callback: () => {
        toaster("warning", "You have 10min remaining");
      },
    },
    {
      time: 20000 * 60,
      callback: () => {
        toaster("warning", "You have 20min remaining");
      },
    },
    {
      time: 0,
      callback: () => {
        toaster("success", "Your time is up! \n Submitting The answer");
        // submit();
        // history.goBack();
      },
    },
  ];

  window.addEventListener("unload", (event) => {
    //call function to save you state in API or save in localStore
    console.log("before reload");
    // localStorage.setItem("xampro-xam", JSON.stringify(options2));
    // l.setItem('state', JSON.stringify(store.getState()))
  });
  const handleSubmit = () => {
    setStartExam(true);
  };
  const handleNext = () => {
    const option = {
      examId: AnalyticalExamPackage?.values,
      questionId: AnalyticalQues?._id,
      setStartExam,
      history,
      id,
    };
    let obj = {
      examStatus: 3,
    };
    console.log("singleAns", singleAns);
    dispatch(submitAnalyticalSingleAns(singleAns, authtoken, option));

    dispatch(submitAnalyticalFullAns(obj, authtoken, option));
  };

  return (
    <div className="exam-ques-footer-main-wrap">
      {/* <PromptMessage
        dependent={isRouteChange}
        header={"Attention!! You are about to leave the exam."}
        content={
          "Are you sure you want to leave? You will not be able to give exam again."
        }
        submit={options2}
      /> */}
      <div className="ques-footer-top">
        <div className="exam-f-left">
          <h5>Question {currentIndex + 1}</h5>
        </div>
        <div className="exam-f-right">
          <div className="exam-h-marks exam-h-t-right-button"></div>
        </div>
      </div>
      <div className="ques-footer-bottom">
        <div
          className="exam-f-left"
          style={{ cursor: "pointer" }}
          onClick={onOpenModal2}
        >
          <React.Fragment>
            <img src={keypad} alt="question" />
          </React.Fragment>
        </div>
        <div className="exam-f-right">
          {false ? (
            "0:0"
          ) : (
            <div className="exam-h-marks">
              <h4>
                <img src={time} alt="" />
                <Timer
                  initialTime={30000 * 60}
                  checkpoints={checkpoints}
                  direction="backward"
                >
                  {({ getTime }) => (
                    <React.Fragment>
                      {setTimeValue(getTime())}
                      <Timer.Minutes />:
                      <Timer.Seconds />
                    </React.Fragment>
                  )}
                </Timer>
              </h4>
            </div>
          )}
        </div>{" "}
        <div className="exam-f-right">
          <div className="exam-h-right-button">
            <Modal
              open={submitted}
              onClose={() => setSubmitted(false)}
              classNames={{
                modal: "submitExamModal",
              }}
              center
            >
              <div className="ques-answer-modal-container">
                {/* <img src={submitPopup} alt="" /> */}
                <h6>
                  <b>Want to submit?</b>
                </h6>
                <Row>
                  <Col lg={6}>
                    <div className="popup-answer-modal-single item-1 ">
                      <button onClick={() => setSubmitted(false)}>
                        Cancel
                      </button>
                    </div>
                  </Col>{" "}
                  <Col lg={6}>
                    {/* <div className="popup-answer-modal-single item-2">
                      <button disabled={adding} onClick={submitAnswer}>
                      
                        {adding ? "Submitting..." : "Submit and review "}
                      </button>
                    </div> */}
                  </Col>
                </Row>
              </div>
            </Modal>
            <button onClick={() => handleSubmit()}>
              <img src={papar} alt="" /> Submit
            </button>
          </div>
        </div>
        <Modal
          open={startXam}
          classNames={{
            overlay: "customOverlay",
            modal: "Xam-Confirm-Modal-main-wrap",
          }}
          center
        >
          <div className="text-center">
            <img src={submit} alt="" />
            <p className="mt-4 mb-4">
              Please make sure you have answered all the questions before
              submitting your response.
            </p>
          </div>
          <div className="text-center ">
            <Button
              color="primary"
              outline
              className="me-3"
              onClick={() => setStartExam(false)}
            >
              {" "}
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => handleNext()}
              disabled={adding}
              //   onClick={() => history.push(`/gmat-service/analytical/exam`)}
            >
              {adding ? "Submitting" : "Submit"}
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default withRouter(AnalyticalFooter);
