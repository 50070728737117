import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import serviceArrow from "./icon/serviceArrow.svg";
import "./style/ServiceHome.scss";

const serviceInfo = [
  {
    title: "IELTS",
    subTitle: "General",
    url: "/packages/Ielts",
  },
  {
    title: "IELTS",
    subTitle: "Academic",
    url: "/packages/Ielts",
  },
  {
    title: "GMAT",
    subTitle: "General",
    url: "/gmat-package",
  },
  {
    title: "GRE",
    subTitle: "General",
    url: "/upcoming",
  },
  {
    title: "BCS",
    subTitle: "General",
    url: "/upcoming",
  },
];

const ServiceHome = () => {
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);

  const handleUrl = (data) => {
    console.log("hello");
    setRedirect(true);
    console.log("redirect", redirect);
    history.push(`${data?.url}`);
  };
  console.log("redirect", redirect);
  return (
    <div className="home-service-section">
      <Row>
        <Col md={1}></Col>
        {serviceInfo?.map((data, idx) => (
          <>
            <Col md={2}>
              <div
                className="all-service-info mb-3 mb-md-0"
                onClick={() => handleUrl(data)}
                style={
                  redirect ? { zIndex: "10000000000000" } : { zIndex: "1" }
                }
              >
                <div>
                  <h5>{data?.title}</h5>
                  <p>{data?.subTitle}</p>
                </div>
                <div>
                  <img
                    src={serviceArrow}
                    alt=""
                    className="service-arrow-image"
                  />
                </div>
              </div>
            </Col>
          </>
        ))}
        <Col md={1}></Col>
      </Row>
    </div>
  );
};

export default ServiceHome;
