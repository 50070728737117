import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, ModalHeader, Row } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Modal } from "reactstrap";
import star from "../../assets/images/Icon/star.svg";
import IetlsImage from "../../assets/images/ielts-logo.jpg";

import Loader from "../../components/Atoms/Loader";
import AddLoader from "../../components/Common/AddLoader";
import CouponMessage from "../../components/Common/CouponMessage";
import NoData from "../../components/Common/NoData";
import { imageBaseUrl } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import { getSingleCourse } from "../../store/Course/actions";
import { addWishList, getWishList } from "../../store/Wishlist/actions";
import { addCart } from "../../store/cart/actions";

import FacilitatorIcon from "../Package/PackageIcon/FacilitatorIcon";
// import ListeningIcon from "./PackageIcon/ListeningIcon";
import ListeningIcon from "../Package/PackageIcon/ListeningIcon";

import ReadingIcon from "../Package/PackageIcon/ReadingIcon";

import SpeakingIcon from "../Package/PackageIcon/SpeakingIcon";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { storeDashboardData } from "../../store/Dashboard/actions";
import { getBuyPackageList } from "../../store/actions";
import WritingIcon from "../Package/PackageIcon/WritingIcon";
import createpBanner from "./img/create-banner.png";
import "./student-package.scss";

const GmatPackageList = ({ history }) => {
  const { courseId } = useParams();
  const [modal, setModal] = useState({
    open: false,
    id: "",
  });
  const [modal2, setModal2] = useState(false);

  const dispatch = useDispatch();
  const { singleCourse, packageList, loading, cart } = useSelector((state) => ({
    loading: state.course.singleCourseLoading,
    singleCourse: state.course.singleCourse,
    packageList: state.course.packageList,
    cart: state.CardReducer.cart,
    // error: state.course.error,
  }));

  const cartId = useMemo(() => cart.map((data, idx) => data._id), [cart]);

  const {
    authToken,
    wishList,
    adding,
    id,
    buyPackageList,
    data,
    countryName,
    dollarRate,
  } = useSelector((state) => ({
    allPackage: state.PackageReducer.allPackage,
    countryName: state.PackageReducer.countryName,
    data: state.course.data,
    authToken: state.Login.token,
    loading: state.PackageReducer.packageLoading,
    loading1: state.PackageReducer.loading,
    buyPackageList: state.PackageReducer.buyPackageList,
    id: state.Login.id,
    wishList: state.wishlist.wishList,
    adding: state.wishlist.adding,
    dollarRate: state.PackageReducer.dollarRate,
  }));
  console.log("data", data);
  console.log("countryName", countryName);
  useEffect(() => {
    console.log("gmat-oa", data);
    if (data[2]?._id) {
      dispatch(getSingleCourse(authToken, data[2]?._id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data[0]?._id]);
  const wishListId = wishList.map((data) => data?.package?._id);

  useEffect(() => {
    if (id && authToken) {
      dispatch(getBuyPackageList(authToken));
      dispatch(getWishList(authToken));
    }
    window?.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAddWishlist = (data) => {
    if (id) {
      if (wishListId.includes(data?._id)) {
        const findWishlistId = wishList.find(
          (list) => list?.package?._id === data?._id
        );
        dispatch(addWishList(authToken, findWishlistId?._id, true));
      } else {
        dispatch(addWishList(authToken, data?._id));
      }
    } else {
      toaster("warning", "Please login for add to wishlist");
    }
  };
  const [modalCardTitle, setModalCardTitle] = useState("");
  const [modalCardImage, setModalCardImage] = useState("");
  const [modalCardDuration, setModalCardDuration] = useState("");
  const handleStore = (data) => {
    setModalCardTitle(data?.packageName);
    setModalCardImage(data?.photo);
    setModalCardDuration(data?.validity);

    dispatch(addCart(data));
  };
  const handleRemove = () => {
    dispatch(addWishList(authToken, modal.id, true));
  };
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return IetlsImage;
  };

  const convertToDollar = (data) => {
    console.log("data", data);
    return (data / dollarRate?.BDT).toFixed(2);
  };
  useEffect(() => {
    dispatch(storeDashboardData("screen", false));
  }, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>IELTS Package</title>
      </MetaTags>
      <CouponMessage />
      <Modal
        isOpen={modal.open}
        centered
        toggle={() => setModal({ open: false, id: "" })}
      >
        <div className="p-4 w-100">
          <div>
            <h6>Are you sure you want to remove from wishlist!!</h6>
            <div className="d-flex ">
              <button
                disabled={adding}
                onClick={handleRemove}
                className="btn btn-sm btn-primary"
              >
                Yes, Remove
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        size={"lg"}
        isOpen={modal2}
        centered
        toggle={() => setModal2(false)}
      >
        <ModalHeader className="added-card-header" toggle={modal2}>
          <span>
            <i className="bx bx-check"></i>
            Added to the cart
          </span>
          <button
            onClick={() => {
              setModal2(false);
            }}
          >
            <span></span>
            <span></span>
          </button>
        </ModalHeader>
        <div className="added-card-go-to-card-popup">
          <div className="card-content-info-left">
            <img src={getPhotoUrl(modalCardImage)} alt="" />
            <div className="card-content-info-left-info">
              <h6>{modalCardTitle}</h6>
              <p>
                <i className="bx bx-time-five"></i> Duration -{" "}
                {modalCardDuration} Days{" "}
              </p>
            </div>
          </div>
          <div className="card-content-info-button">
            <button
              onClick={() => history.push(`/cart`)}
              className="btn btn-sm btn-primary"
            >
              Go to cart
            </button>
          </div>
        </div>
      </Modal>
      {adding ? <AddLoader /> : null}
      <Container>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "90vh" }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="ieltspackage-main-wrap-all-package">
              <div className="package-area-title-wrap all-package">
                <div className="package-title-area-left-part">
                  <h2 style={{ fontWeight: "700" }}>GMAT Packages</h2>
                  {/* <p style={{ color: "#74788D" }}>
                  Find the one that suits best
                </p> */}
                </div>
                {/* <div className="package-title-area-right-part">
                <Link to="#">See all package</Link>
              </div> */}
              </div>

              <Row>
                {packageList[`${singleCourse?.courseName}`]?.length ? (
                  packageList[`${singleCourse?.courseName}`]?.map(
                    (data, key) => (
                      <Col lg={4} md={"6"} key={key}>
                        <div className="student-courses-card">
                          <Card>
                            <div
                              className="bookmark-button-wrap position-absolute"
                              style={{
                                background: "#fff",
                                padding: "5px",
                                lineHeight: "5px",
                                borderRadius: "5px",
                                top: "2%",
                                right: "2%",
                                fontSize: "18px",
                              }}
                              onClick={() => handleAddWishlist(data)}
                            >
                              {wishListId.includes(data?._id) ? (
                                <i
                                  className="bx bxs-bookmark"
                                  style={{ color: "#004AB8" }}
                                ></i>
                              ) : (
                                <i
                                  className="bx bx-bookmark"
                                  style={{ color: "#004AB8" }}
                                ></i>
                              )}
                            </div>
                            <Link to={`/package-details/${data._id}`}>
                              <div
                                className="card-img-top package-card-image"
                                style={{
                                  backgroundImage: `url(${
                                    data?.photo
                                      ? getPhotoUrl(data?.photo)
                                      : IetlsImage
                                  })`,
                                }}
                              ></div>
                            </Link>
                            <Card.Body>
                              <Link to={`/package-details/${data._id}`}>
                                <div className="course-review--card-area-top">
                                  <div className="student-courses-review">
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <span> 5.00(5)</span>
                                  </div>
                                  <Card.Text className="student-courses-timeline header-right-timeline-header">
                                    <i className="bx bx-time"></i>
                                    {data?.validity} Days
                                  </Card.Text>
                                </div>

                                <Card.Title>{data.packageName}</Card.Title>
                                <div className="package-subject-name-list-item">
                                  {data?.packageService.map((service, idx) => (
                                    <>
                                      {service?.serviceName.toLowerCase() ===
                                        "quantative reasoning" && (
                                        <div
                                          className="package-subject-name-list-item-single-item"
                                          key={idx}
                                        >
                                          <ListeningIcon />
                                          <span>
                                            {data?.quantativeDataSufficencyQ +
                                              data?.quantativeProblemSolvingQ}{" "}
                                            {service?.serviceName}
                                          </span>
                                        </div>
                                      )}
                                      {service?.serviceName.toLowerCase() ===
                                        "verbal reasoning" && (
                                        <div
                                          className="package-subject-name-list-item-single-item"
                                          key={idx}
                                        >
                                          <ReadingIcon />
                                          <span>
                                            {data?.vebalReadingQ +
                                              data?.verbalCriticalReasoningQ +
                                              data?.verbalSentenceCorrectionQ}{" "}
                                            {service?.serviceName}
                                          </span>
                                        </div>
                                      )}
                                      {service?.serviceName.toLowerCase() ===
                                        "integrated rejoining" && (
                                        <div
                                          className="package-subject-name-list-item-single-item"
                                          key={idx}
                                        >
                                          <WritingIcon />
                                          <span>
                                            {data?.tableAnalysisOneQ +
                                              data?.tableAnalysisTwoQ +
                                              data?.twoPartAnalysisOneQ +
                                              data?.twoPartAnalysisTwoQ +
                                              data?.graphicalInterfaceQ +
                                              1 +
                                              data?.multiSourceQ}{" "}
                                            {service?.serviceName}
                                          </span>
                                        </div>
                                      )}
                                      {service?.serviceName.toLowerCase() ===
                                        "quantative reasoning" && (
                                        <div
                                          className="package-subject-name-list-item-single-item"
                                          key={idx}
                                        >
                                          <SpeakingIcon />
                                          <span>
                                            {data?.quantativeDataSufficencyQ +
                                              data?.quantativeProblemSolvingQ}{" "}
                                            {service?.serviceName}
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  ))}
                                  {data?.totalFacilitator ? (
                                    <div className="package-subject-name-list-item-single-item facilitator-review">
                                      <FacilitatorIcon />

                                      <span>
                                        {data?.totalFacilitator} One to One
                                        Review
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Link>
                              <div className="student-courses-card-button">
                                {/* <Link to={`/cart-page/${data._id}`}> */}
                                {cartId?.includes(data?._id) ? (
                                  <Button
                                    disabled={buyPackageList?.[data?._id] > 0}
                                    onClick={() =>
                                      // toaster(
                                      //   "warning",
                                      //   "Package is already to card added!"
                                      // )
                                      history.push(`/cart`)
                                    }
                                  >
                                    {buyPackageList?.[data?._id] > 0
                                      ? "Purchased"
                                      : "Go to cart"}
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={buyPackageList?.[data?._id] > 0}
                                    onClick={() => {
                                      handleStore(data);
                                      setModal2(true);
                                    }}
                                  >
                                    {buyPackageList?.[data?._id] > 0
                                      ? "Purchased"
                                      : "Add to cart"}
                                  </Button>
                                )}
                                {/* </Link> */}
                                {countryName === "Bangladesh" ? (
                                  <p>
                                    {data?.discount > 0 ? (
                                      <>
                                        <del style={{ color: "#0052CC" }}>
                                          TK. {data.price}
                                        </del>{" "}
                                        <b>TK. {data.price - data?.discount}</b>
                                      </>
                                    ) : (
                                      <b>TK. {data.price}</b>
                                    )}
                                  </p>
                                ) : (
                                  <p>
                                    {data?.discount > 0 ? (
                                      <>
                                        <del style={{ color: "#0052CC" }}>
                                          &#36; {convertToDollar(data.price)}
                                        </del>{" "}
                                        <b>
                                          &#36;{" "}
                                          {convertToDollar(
                                            data.price - data?.discount
                                          )}
                                        </b>
                                      </>
                                    ) : (
                                      <b>
                                        {" "}
                                        &#36; {convertToDollar(data.price)}
                                      </b>
                                    )}
                                  </p>
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </Col>
                    )
                  )
                ) : (
                  <div>
                    <NoData>
                      <p>No General Packages</p>
                    </NoData>
                  </div>
                )}
                {/* <Col lg={4} md={"6"}>
                <div className="">
                  <div className="student-courses-card create-own-package-wrap">
                    <Card>
                      <Link
                        className="student-create-own-package"
                        to="/packages/622c6437b122d93e541b44bb"
                      >
                        <div className="student-create-own-package-button">
                          <i className="bx bx-plus"></i>
                        </div>
                        <h6>
                          Create your own <br /> package
                        </h6>
                      </Link>
                    </Card>
                  </div>
                </div>
              </Col> */}
                {/* {loading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : (
            (allPackage.AllPackages || []).map((data, key) => {
              return (
                <Col lg={4} key={key}>
                  <div className="student-courses-card">
                    <Card>
                      <div
                        className="bookmark-button-wrap position-absolute"
                        style={{
                          background: '#fff',
                          padding: '5px',
                          lineHeight: '5px',
                          borderRadius: '5px',
                          top: '2%',
                          right: '2%',
                          fontSize: '18px',
                        }}
                        onClick={() => handleAddWishlist(data)}
                      >
                        {wishListId.includes(data?._id) ? (
                          <i
                            className="bx bxs-bookmark"
                            style={{ color: '#004AB8' }}
                          ></i>
                        ) : (
                          <i
                            className="bx bx-bookmark"
                            style={{ color: '#004AB8' }}
                          ></i>
                        )}
                      </div>
                      <Card.Img
                        variant="top"
                        src={
                          data?.photo
                            ? imageBaseUrl + data?.photo
                            : IetlsImage
                        }
                      />
                      <Card.Body>
                        <div className="student-courses-review">
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <span> 5.00(5)</span>
                        </div>
                        <Link to={`/package-details/${data._id}`}>
                          <Card.Title>{data.packageName}</Card.Title>
                        </Link>

                        <Card.Text className="student-courses-timeline">
                          <img src={clock} alt="" />
                          Duration - {data.packageLength} Days
                        </Card.Text>
                        <div className="student-courses-card-button">
                          <Button onClick={() => handleStore(data)}>
                            Add to cart
                          </Button>
                          <p>
                            {data?.discount > 0 ? (
                              <div>
                                TK. <del>{data.price}</del>{' '}
                                <b>{data?.discount}</b>
                              </div>
                            ) : (
                              <b>TK. {data.price}</b>
                            )}
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              );
            })
          )} */}
              </Row>
            </div>
          </>
        )}

        <div className="create-new-package-request-area-wrap">
          <div className="create-new-package-request-title">
            <h3>Create package</h3>
          </div>
          <div className="package-page-create-package">
            <img
              src={createpBanner}
              alt=""
              className="banner-image-right-area"
            />
            <div className="">
              <h2>
                Would you like to create <br /> your own package?{" "}
              </h2>
              <Link to="/create-package">Create Package</Link>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default GmatPackageList;
