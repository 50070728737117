import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAnalyticalExamPackage,
  getAnalyticalQues,
  storeAnalyticalData,
} from "../../../store/GMAT/Analytical/action";
import AnalyticalFooter from "./AnalyticalFooter";
import SingleAnalyticalQues from "./SingleAnalyticalQues";

const AnalyticalQuesInfo = () => {
  const dispatch = useDispatch();
  const [object, setObject] = useState({});
  const [totalDS, setTotalDS] = useState(2);
  const [totalPS, setTotalPS] = useState(2);
  const { id } = useParams();
  //   const { assessmentId, examType, currentIndex } = useParams();
  const { authtoken, AnalyticalQues, currentIndex } = useSelector((state) => ({
    authtoken: state.Login.token,
    AnalyticalQues: state.AnalyticalQuesReducer.AnalyticalQues,
    AnalyticalQuesLoading: state.AnalyticalQuesReducer.AnalyticalQuesLoading,
  }));
  console.log("AnalyticalQues", AnalyticalQues);
  useEffect(() => {
    dispatch(getAnalyticalQues(authtoken, 1, 1, id));
  }, []);
  useEffect(() => {
    dispatch(getAnalyticalExamPackage(authtoken, id));
  }, []);

  //   useEffect(() => {
  //     const debounce = setTimeout(() => {
  //       if (object?.answer) {
  //         submitData(object);
  //         setObject({});
  //       }
  //     }, 300);
  //     return () => clearTimeout(debounce);

  //   }, [object?.answer]);

  const handleStoreAnswer = ({ e, data, number }) => {
    console.log("e", e.target.value);
    console.log("data", data);
    const { value } = e.target;
    console.log("data", data);
    const serial = number - 1;
    const body = {
      serial: 1,
      answer: value,
      difficultyLevel: 1,
      length: 12,
    };
    dispatch(storeAnalyticalData("singleAns", body));
    submitData(body);
  };

  const submitData = (data) => {
    console.log("hellodxzc", data);
    // const options = {
    //   type: examType,
    //   assessmentId,
    //   answerId,
    //   history,
    //   questionId: question?._id,
    // };
    // // console.log(`options`, options, data);
    // dispatch(submitAptitudeAnsOneByOne({ data, options }));
  };

  return (
    <React.Fragment>
      <div className="ques-right-part-container">
        <SingleAnalyticalQues
          handleChangeMcq={handleStoreAnswer}
          questions={AnalyticalQues}
          questionNumber={currentIndex + 1}
        />
      </div>
      <AnalyticalFooter />
    </React.Fragment>
  );
};

export default AnalyticalQuesInfo;
