import { call, put, takeEvery } from "redux-saga/effects";
import { getData, patchData } from "../../../helpers/backend_helper";
import { toaster } from "../../../helpers/custom/Toast";
import { storeIRData } from "../IR/action";
import { storeQuantitativeData } from "../Quantitative/action";
import { storeVerbalData } from "../Verbal/action";
import {
  cancelExamFail,
  cancelExamSuccess,
  getAnalyticalExamPackageFail,
  getAnalyticalExamPackageSuccess,
  getAnalyticalQuesFail,
  getAnalyticalQuesSuccess,
  getGmatResultFail,
  getGmatResultSuccess,
  getGmatSinglePackageFail,
  getGmatSinglePackageSuccess,
  submitAnalyticalFullAnsFail,
  submitAnalyticalFullAnsSuccess,
  submitAnalyticalSingleAnsFail,
  submitAnalyticalSingleAnsSuccess,
} from "./action";
import {
  CANCEL_EXAM,
  GET_ANALYTICAL_EXAM_PACKAGE,
  GET_ANALYTICAL_QUES,
  GET_GMAT_RESULT,
  GET_SINGLE_GMAT_PACKAGE,
  SUBMIT_ANALYTICAL_FULL_ANS,
  SUBMIT_ANALYTICAL_SINGLE_ANS,
} from "./actionType";

function* onGetAnalyticalQues({
  payload: { token, Section, difficultyLevel, PackageId },
}) {
  try {
    const url = `package/get-analytical-question/${Section}/${difficultyLevel}/${PackageId}`;
    const response = yield call(getData, url, token);
    yield put(getAnalyticalQuesSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getAnalyticalQuesFail(message));
    toaster("error", message);
  }
}

function* onGetAnalyticalExamPackage({ payload: { token, PackageId } }) {
  try {
    const url = `/package/start-analytical-exam/${PackageId}`;
    const response = yield call(getData, url, token);
    yield put(getAnalyticalExamPackageSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getAnalyticalExamPackageFail(message));
    toaster("error", message);
  }
}
function* onAddAnalyticalSingleAns({ payload: { obj, authToken, option } }) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, obj);
  console.log("option", option);
  try {
    const url = `package/analytical-answer-one-by-one/${option?.examId}/1/${option?.questionId}`;
    const response = yield call(patchData, url, obj, authToken);
    if (response?.status === "succes") {
      option?.setStartExam(false);
      option?.history?.push("/analytical-result");
      yield put(submitAnalyticalSingleAnsSuccess(response?.message));
    }

    // toaster("success", `FAQ type added Successfully`);
    // history.push("/faqType");
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(submitAnalyticalSingleAnsFail(message));
  }
}
function* onAddAnalyticalFullAns({ payload: { obj, authToken, option } }) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, obj);
  try {
    const url = `package/analytical-answer-submit/${option?.examId}`;
    const response = yield call(patchData, url, obj, authToken);
    yield put(submitAnalyticalFullAnsSuccess(response?.message));
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(submitAnalyticalFullAnsFail(message));
  }
}

function* onCancelExam({ payload: { token } }) {
  try {
    const url = `package/clean-gmat-exam-data`;
    const response = yield call(getData, url, token);
    yield put(cancelExamSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(cancelExamFail(message));
    toaster("error", message);
  }
}
function* onGetGmatResult({ payload: { token } }) {
  try {
    const url = `package/get-gmat-result`;
    const response = yield call(getData, url, token);
    yield put(getGmatResultSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getGmatResultFail(message));
    toaster("error", message);
  }
}
function* onGetGmatSinglePackage({ payload: { token, packageId } }) {
  try {
    const url = `package/getsinglepackage/${packageId}`;
    const response = yield call(getData, url, token);
    console.log("response", response);
    if (response?.status === "success") {
      yield put(getGmatSinglePackageSuccess(response?.getData));
      let packageInfo = response?.singlePackage;
      console.log("packageInfo", packageInfo);
      yield put(
        storeQuantitativeData(
          "totalDataSufficiency",
          packageInfo?.quantativeDataSufficencyQ
        )
      );
      yield put(
        storeQuantitativeData(
          "totalProblemSolving",
          packageInfo?.quantativeProblemSolvingQ
        )
      );
      yield put(
        storeQuantitativeData(
          "totalQuantitativeQues",
          packageInfo?.quantativeProblemSolvingQ +
            packageInfo?.quantativeDataSufficencyQ
        )
      );
      yield put(
        storeVerbalData("readingComprehension", packageInfo?.vebalReadingQ)
      );
      yield put(
        storeVerbalData(
          "sentenceEquivalence",
          packageInfo?.verbalSentenceCorrectionQ
        )
      );
      yield put(
        storeVerbalData(
          "textComprehension",
          packageInfo?.verbalCriticalReasoningQ
        )
      );
      yield put(
        storeVerbalData(
          "totalVerbalQuestion",
          packageInfo?.vebalReadingQ +
            packageInfo?.verbalSentenceCorrectionQ +
            packageInfo?.verbalCriticalReasoningQ
        )
      );
      yield put(
        storeIRData("tableAnalysisPartOne", packageInfo?.tableAnalysisOneQ)
      );
      yield put(
        storeIRData("tableAnalysisPartTwo", packageInfo?.tableAnalysisTwoQ)
      );
      yield put(
        storeIRData("twoPartAnalysisOne", packageInfo?.twoPartAnalysisOneQ)
      );
      yield put(
        storeIRData("twoPartAnalysisTwo", packageInfo?.twoPartAnalysisTwoQ)
      );
      yield put(
        storeIRData("GraphicalInterpretation", packageInfo?.graphicalInterfaceQ)
      );
      yield put(storeIRData("multiSource", packageInfo?.multiSourceQ));
      console.log(
        "kj",
        packageInfo?.tableAnalysisOneQ +
          packageInfo?.tableAnalysisTwoQ +
          packageInfo?.twoPartAnalysisOneQ +
          packageInfo?.twoPartAnalysisTwoQ +
          packageInfo?.graphicalInterfaceQ +
          packageInfo?.multiSourceQ
      );
      yield put(
        storeIRData(
          "totalIrQues",
          packageInfo?.tableAnalysisOneQ +
            packageInfo?.tableAnalysisTwoQ +
            packageInfo?.twoPartAnalysisOneQ +
            packageInfo?.twoPartAnalysisTwoQ +
            packageInfo?.graphicalInterfaceQ +
            packageInfo?.multiSourceQ
        )
      );
    }
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getGmatSinglePackageFail(message));
    toaster("error", message);
  }
}
function* AnalyticalQuesSaga() {
  yield takeEvery(GET_ANALYTICAL_QUES, onGetAnalyticalQues);
  yield takeEvery(GET_ANALYTICAL_EXAM_PACKAGE, onGetAnalyticalExamPackage);
  yield takeEvery(SUBMIT_ANALYTICAL_SINGLE_ANS, onAddAnalyticalSingleAns);

  yield takeEvery(SUBMIT_ANALYTICAL_FULL_ANS, onAddAnalyticalFullAns);
  yield takeEvery(GET_GMAT_RESULT, onGetGmatResult);
  yield takeEvery(GET_SINGLE_GMAT_PACKAGE, onGetGmatSinglePackage);
  yield takeEvery(CANCEL_EXAM, onCancelExam);
}

export default AnalyticalQuesSaga;
