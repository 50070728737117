export const GET_IR_QUES = "GET_IR_QUES";
export const GET_IR_QUES_SUCCESS = "GET_IR_QUES_SUCCESS";
export const GET_IR_QUES_FAIL = "GET_IR_QUES_FAIL";

export const GET_IR_EXAM_PACKAGE = "GET_IR_EXAM_PACKAGE";
export const GET_IR_EXAM_PACKAGE_SUCCESS = "GET_IR_EXAM_PACKAGE_SUCCESS";
export const GET_IR_EXAM_PACKAGE_FAIL = "GET_IR_EXAM_PACKAGE_FAIL";

export const SUBMIT_IR_SINGLE_ANS = "SUBMIT_IR_SINGLE_ANS";
export const SUBMIT_IR_SINGLE_ANS_SUCCESS = "SUBMIT_IR_SINGLE_ANS_SUCCESS";
export const SUBMIT_IR_SINGLE_ANS_FAIL = "SUBMIT_IR_SINGLE_ANS_FAIL";

export const SUBMIT_IR_FULL_ANS = "SUBMIT_IR_FULL_ANS";
export const SUBMIT_IR_FULL_ANS_SUCCESS = "SUBMIT_IR_FULL_ANS_SUCCESS";
export const SUBMIT_IR_FULL_ANS_FAIL = "SUBMIT_IR_FULL_ANS_FAIL";

export const GET_IR_RESULT = "GET_IR_RESULT";
export const GET_IR_RESULT_SUCCESS = "GET_IR_RESULT_SUCCESS";
export const GET_IR_RESULT_FAIL = "GET_IR_RESULT_FAIL";

export const GET_ALL_TYPE_QUESTION = "GET_ALL_TYPE_QUESTION";

export const CLEAR_IR_DATA = "CLEAR_IR_DATA";

export const STORE_IR_QUES = "STORE_IR_QUES";
