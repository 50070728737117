import { combineReducers } from "redux";
import AnswerReducer from "./Answer/reducers";
import ForgetPassword from "./auth/forgetpwd/reducer";
// Authentication
import Login from "./auth/login/reducer";
import LoginError from "./auth/loginError/reducer";
import Profile from "./auth/profile/reducer";
//update profile password
import updateProfilePassword from "./auth/profilepwdupdate/reducer";
import Account from "./auth/register/reducer";
import updatePass from "./auth/updatePassword/reducer";
//verifyEmailSagaReducer
import Course from "./Course/reducer";
import CourseType from "./CourseType/reducer";
import DashboardReducer from "./Dashboard/reducer";
import FAQReducer from "./FAQ/reducers";
import FacilitatorReducer from "./Facilitator/reducers";
import AnalyticalQuesReducer from "./GMAT/Analytical/reducer";
import IRQuesReducer from "./GMAT/IR/reducer";
import QuantitativeQuestionReducer from "./GMAT/Quantitative/reducer";
import verbalQuestionReducer from "./GMAT/Verbal/reducer";
import OrderReducer from "./Order/reducers";
import OwnPackageReducer from "./OwnPackage/reducers";
import PackageReducer from "./Package/reducers";
import QuesReducer from "./Question/reducers";
import resultReducer from "./Result/reducers";
import ReviewAnswer from "./ReviewAnswer/reducers";
import serviceReducer from "./Service/reducers";
import speakingSetReducer from "./Speaking/reducers";
import speakingQues from "./SpeakingQuestion/reducers";
import wishListReducer from "./Wishlist/reducers";
import verifyEmail from "./auth/verifyEmail/reducer";
import CardReducer from "./cart/reducers";
import ChatReducer from "./chat/reducers";
import Layout from "./layout/reducer";
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  LoginError,
  updatePass,
  Account,
  updateProfilePassword,
  ForgetPassword,
  Profile,
  verifyEmail,
  PackageReducer,
  serviceReducer,
  OrderReducer,
  QuesReducer,
  AnswerReducer,
  resultReducer,
  CardReducer,
  wishlist: wishListReducer,
  course: Course,
  courseType: CourseType,
  review: ReviewAnswer,
  speakingSet: speakingSetReducer,
  speakingQues,
  facilitator: FacilitatorReducer,
  DashboardReducer,
  OwnPackageReducer,
  ChatReducer,
  FAQReducer,
  QuantitativeQuestionReducer,
  AnalyticalQuesReducer,
  verbalQuestionReducer,
  IRQuesReducer,
});

export default rootReducer;
