import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import Loader from "../../../components/Atoms/Loader";
import { getGmatResult } from "../../../store/GMAT/Analytical/action";
import "../dashboard.scss";
let obj = {
  25: {
    30: 480,
    31: 480,
    32: 490,
    33: 500,
    34: 500,
    35: 510,
    36: 510,
    37: 520,
    38: 520,
    39: 540,
    40: 550,
    41: 550,
    42: 560,
    43: 570,
    44: 580,
    45: 580,
    46: 590,
    47: 600,
    48: 600,
    49: 610,
    50: 620,
    51: 630,
  },
  26: {
    30: 480,
    31: 490,
    32: 490,
    33: 500,
    34: 510,
    35: 510,
    36: 520,
    37: 530,
    38: 540,
    39: 550,
    40: 550,
    41: 560,
    42: 560,
    43: 580,
    44: 580,
    45: 590,
    46: 600,
    47: 610,
    48: 610,
    49: 620,
    50: 630,
    51: 640,
  },
  27: {
    30: 480,
    31: 500,
    32: 500,
    33: 510,
    34: 520,
    35: 520,
    36: 530,
    37: 540,
    38: 550,
    39: 550,
    40: 560,
    41: 570,
    42: 580,
    43: 580,
    44: 590,
    45: 600,
    46: 610,
    47: 610,
    48: 620,
    49: 630,
    50: 640,
    51: 640,
  },
  28: {
    30: 500,
    31: 500,
    32: 500,
    33: 520,
    34: 520,
    35: 530,
    36: 540,
    37: 550,
    38: 550,
    39: 560,
    40: 570,
    41: 580,
    42: 580,
    43: 590,
    44: 600,
    45: 610,
    46: 610,
    47: 620,
    48: 630,
    49: 640,
    50: 640,
    51: 650,
  },
  29: {
    30: 500,
    31: 510,
    32: 510,
    33: 520,
    34: 530,
    35: 540,
    36: 550,
    37: 550,
    38: 560,
    39: 570,
    40: 580,
    41: 580,
    42: 590,
    43: 600,
    44: 610,
    45: 610,
    46: 620,
    47: 630,
    48: 640,
    49: 640,
    50: 650,
    51: 660,
  },
  30: {
    30: 510,
    31: 520,
    32: 520,
    33: 530,
    34: 540,
    35: 550,
    36: 550,
    37: 560,
    38: 570,
    39: 580,
    40: 580,
    41: 590,
    42: 600,
    43: 610,
    44: 610,
    45: 620,
    46: 630,
    47: 640,
    48: 640,
    49: 650,
    50: 660,
    51: 670,
  },
  31: {
    30: 520,
    31: 530,
    32: 530,
    33: 540,
    34: 550,
    35: 560,
    36: 560,
    37: 570,
    38: 580,
    39: 580,
    40: 590,
    41: 600,
    42: 610,
    43: 610,
    44: 620,
    45: 630,
    46: 640,
    47: 640,
    48: 650,
    49: 660,
    50: 670,
    51: 670,
  },
  32: {
    30: 530,
    31: 530,
    32: 530,
    33: 550,
    34: 560,
    35: 560,
    36: 570,
    37: 580,
    38: 580,
    39: 590,
    40: 600,
    41: 610,
    42: 610,
    43: 620,
    44: 630,
    45: 640,
    46: 640,
    47: 650,
    48: 660,
    49: 670,
    50: 670,
    51: 680,
  },
  33: {
    30: 530,
    31: 540,
    32: 540,
    33: 560,
    34: 560,
    35: 570,
    36: 580,
    37: 580,
    38: 590,
    39: 600,
    40: 610,
    41: 610,
    42: 620,
    43: 630,
    44: 640,
    45: 640,
    46: 650,
    47: 660,
    48: 670,
    49: 670,
    50: 680,
    51: 690,
  },

  34: {
    30: 540,
    31: 550,
    32: 550,
    33: 560,
    34: 570,
    35: 580,
    36: 580,
    37: 590,
    38: 600,
    39: 610,
    40: 610,
    41: 620,
    42: 630,
    43: 640,
    44: 640,
    45: 650,
    46: 660,
    47: 660,
    48: 680,
    49: 680,
    50: 690,
    51: 690,
  },
  35: {
    30: 550,
    31: 560,
    32: 560,
    33: 570,
    34: 580,
    35: 580,
    36: 590,
    37: 600,
    38: 610,
    39: 610,
    40: 620,
    41: 630,
    42: 640,
    43: 640,
    44: 650,
    45: 660,
    46: 670,
    47: 670,
    48: 680,
    49: 690,
    50: 690,
    51: 700,
  },
  36: {
    30: 560,
    31: 560,
    32: 570,
    33: 580,
    34: 580,
    35: 590,
    36: 600,
    37: 610,
    38: 610,
    39: 620,
    40: 630,
    41: 640,
    42: 640,
    43: 650,
    44: 660,
    45: 670,
    46: 670,
    47: 680,
    48: 690,
    49: 690,
    50: 700,
    51: 710,
  },
  37: {
    30: 560,
    31: 570,
    32: 570,
    33: 580,
    34: 590,
    35: 600,
    36: 610,
    37: 610,
    38: 620,
    39: 620,
    40: 640,
    41: 640,
    42: 650,
    43: 660,
    44: 670,
    45: 670,
    46: 680,
    47: 690,
    48: 690,
    49: 700,
    50: 710,
    51: 720,
  },
  38: {
    30: 570,
    31: 580,
    32: 580,
    33: 590,
    34: 600,
    35: 600,
    36: 610,
    37: 620,
    38: 630,
    39: 640,
    40: 640,
    41: 650,
    42: 660,
    43: 670,
    44: 680,
    45: 680,
    46: 690,
    47: 690,
    48: 700,
    49: 710,
    50: 720,
    51: 730,
  },
  39: {
    30: 580,
    31: 580,
    32: 590,
    33: 600,
    34: 610,
    35: 610,
    36: 620,
    37: 630,
    38: 640,
    39: 640,
    40: 650,
    41: 660,
    42: 670,
    43: 670,
    44: 680,
    45: 690,
    46: 690,
    47: 700,
    48: 710,
    49: 720,
    50: 730,
    51: 740,
  },
  40: {
    30: 580,
    31: 590,
    32: 600,
    33: 610,
    34: 610,
    35: 620,
    36: 630,
    37: 640,
    38: 640,
    39: 650,
    40: 660,
    41: 670,
    42: 670,
    43: 680,
    44: 690,
    45: 690,
    46: 700,
    47: 710,
    48: 720,
    49: 730,
    50: 740,
    51: 750,
  },
  41: {
    30: 590,
    31: 600,
    32: 610,
    33: 610,
    34: 620,
    35: 630,
    36: 640,
    37: 640,
    38: 650,
    39: 660,
    40: 670,
    41: 670,
    42: 680,
    43: 690,
    44: 690,
    45: 700,
    46: 710,
    47: 720,
    48: 720,
    49: 740,
    50: 740,
    51: 750,
  },
  42: {
    30: 600,
    31: 610,
    32: 610,
    33: 620,
    34: 630,
    35: 640,
    36: 640,
    37: 650,
    38: 660,
    39: 670,
    40: 680,
    41: 680,
    42: 690,
    43: 690,
    44: 700,
    45: 700,
    46: 710,
    47: 720,
    48: 730,
    49: 740,
    50: 750,
    51: 760,
  },
  43: {
    30: 610,
    31: 610,
    32: 620,
    33: 630,
    34: 640,
    35: 640,
    36: 650,
    37: 660,
    38: 670,
    39: 670,
    40: 680,
    41: 690,
    42: 690,
    43: 700,
    44: 710,
    45: 720,
    46: 720,
    47: 730,
    48: 740,
    49: 740,
    50: 750,
    51: 760,
  },
  44: {
    30: 610,
    31: 620,
    32: 630,
    33: 640,
    34: 640,
    35: 650,
    36: 660,
    37: 670,
    38: 670,
    39: 680,
    40: 690,
    41: 690,
    42: 700,
    43: 710,
    44: 720,
    45: 730,
    46: 730,
    47: 740,
    48: 740,
    49: 750,
    50: 760,
    51: 770,
  },
  45: {
    30: 620,
    31: 630,
    32: 640,
    33: 640,
    34: 650,
    35: 660,
    36: 670,
    37: 670,
    38: 680,
    39: 680,
    40: 690,
    41: 700,
    42: 710,
    43: 720,
    44: 720,
    45: 730,
    46: 740,
    47: 740,
    48: 750,
    49: 760,
    50: 770,
    51: 780,
  },
  46: {
    30: 630,
    31: 640,
    32: 640,
    33: 650,
    34: 660,
    35: 670,
    36: 670,
    37: 680,
    38: 690,
    39: 690,
    40: 700,
    41: 710,
    42: 710,
    43: 720,
    44: 730,
    45: 740,
    46: 740,
    47: 750,
    48: 760,
    49: 770,
    50: 770,
    51: 780,
  },
  47: {
    30: 640,
    31: 640,
    32: 650,
    33: 660,
    34: 670,
    35: 670,
    36: 680,
    37: 690,
    38: 690,
    39: 700,
    40: 710,
    41: 720,
    42: 720,
    43: 730,
    44: 740,
    45: 740,
    46: 750,
    47: 760,
    48: 760,
    49: 770,
    50: 780,
    51: 790,
  },
  48: {
    30: 640,
    31: 650,
    32: 660,
    33: 670,
    34: 670,
    35: 680,
    36: 690,
    37: 690,
    38: 700,
    39: 710,
    40: 720,
    41: 720,
    42: 730,
    43: 730,
    44: 740,
    45: 750,
    46: 760,
    47: 770,
    48: 770,
    49: 780,
    50: 790,
    51: 790,
  },
  49: {
    30: 650,
    31: 660,
    32: 670,
    33: 670,
    34: 680,
    35: 690,
    36: 690,
    37: 700,
    38: 710,
    39: 720,
    40: 720,
    41: 730,
    42: 740,
    43: 740,
    44: 750,
    45: 760,
    46: 770,
    47: 770,
    48: 780,
    49: 780,
    50: 790,
    51: 800,
  },
  50: {
    30: 660,
    31: 670,
    32: 670,
    33: 680,
    34: 690,
    35: 690,
    36: 700,
    37: 710,
    38: 720,
    39: 720,
    40: 730,
    41: 740,
    42: 740,
    43: 750,
    44: 760,
    45: 770,
    46: 770,
    47: 780,
    48: 780,
    49: 790,
    50: 800,
    51: 800,
  },
  51: {
    30: 670,
    31: 670,
    32: 680,
    33: 690,
    34: 690,
    35: 700,
    36: 710,
    37: 720,
    38: 720,
    39: 730,
    40: 740,
    41: 740,
    42: 750,
    43: 760,
    44: 770,
    45: 770,
    46: 780,
    47: 780,
    48: 790,
    49: 800,
    50: 800,
    51: 800,
  },
};
let quant = {
  51: 97,
  50: 87,
  49: 73,
  48: 65,
  47: 57,
  46: 53,
  45: 50,
  44: 44,
  43: 41,
  42: 36,
  41: 34,
  40: 32,
  39: 28,
  38: 27,
  37: 25,
  36: 22,
  35: 20,
  34: 19,
  33: 17,
  32: 15,
  31: 14,
  30: 13,
  29: 11,
  28: 11,
  27: 9,
  26: 8,
  25: 7,
  24: 7,
  23: 6,
  22: 5,
  21: 5,
  20: 4,
  19: 4,
  18: 4,
  17: 3,
  16: 3,
  15: 3,
  14: 2,
  13: 2,
  12: 2,
  11: 1,
  10: 1,
  9: 1,
  8: 1,
  7: 1,
  6: 0,
  5: 0,
  4: 0,
  3: 0,
  2: 0,
  1: 0,
};
let verbal = {
  51: 99,
  50: 99,
  49: 99,
  48: 99,
  47: 99,
  46: 99,
  45: 99,
  44: 98,
  43: 98,
  42: 96,
  41: 93,
  40: 90,
  39: 88,
  38: 84,
  37: 81,
  36: 79,
  35: 74,
  34: 69,
  33: 67,
  32: 64,
  31: 59,
  30: 56,
  29: 54,
  28: 49,
  27: 44,
  26: 41,
  25: 37,
  24: 34,
  23: 30,
  22: 28,
  21: 24,
  20: 21,
  19: 17,
  18: 16,
  17: 13,
  16: 11,
  15: 9,
  14: 8,
  13: 6,
  12: 4,
  11: 3,
  10: 2,
  9: 2,
  8: 1,
  7: 1,
  6: 0,
  5: 0,
  4: 0,
  3: 0,
  2: 0,
  1: 0,
};
let verbalData = {
  51: 99,
  50: 99,
  49: 99,
  48: 99,
  47: 99,
  46: 99,
  45: 99,
  44: 98,
  43: 98,
  42: 96,
  41: 93,
  40: 90,
  39: 88,
  38: 84,
  37: 81,
  36: 79,
  35: 74,
  34: 69,
  33: 67,
  32: 64,
  31: 59,
  30: 56,
  29: 54,
  28: 49,
  27: 44,
  26: 41,
  25: 37,
  24: 34,
  23: 30,
  22: 28,
  21: 24,
  20: 21,
  19: 17,
  18: 16,
  17: 13,
  16: 11,
  15: 9,
  14: 8,
  13: 6,
  12: 4,
  11: 3,
  10: 2,
  9: 2,
  8: 1,
  7: 1,
  6: 0,
  5: 0,
  4: 0,
  3: 0,
  2: 0,
  1: 0,
};
const GmatResult = () => {
  const dispatch = useDispatch();
  const { authtoken, gmatResult, gmatResultLoading } = useSelector((state) => ({
    authtoken: state.Login.token,
    gmatResult: state.AnalyticalQuesReducer.gmatResult,
    gmatResultLoading: state.AnalyticalQuesReducer.gmatResultLoading,
  }));

  useEffect(() => {
    dispatch(getGmatResult(authtoken));
  }, []);

  return (
    <div>
      {gmatResultLoading ? (
        <Loader />
      ) : (
        <div className="mb-5">
          <h5 className="mb-3">Gmat Result</h5>
          <Table bordered>
            <thead>
              <tr className="gmat-result-table-head">
                <th>Test Date</th>
                <th>Quantitative</th>
                <th>Verbal</th>

                <th>Total</th>
                <th>Analytical wri..</th>
                <th>IR</th>
              </tr>
            </thead>
            <tbody>
              {gmatResult?.values?.quantativeResult?.map((data, idx) => (
                <>
                  <tr>
                    <td key={idx}>{data?.createdAt?.slice(0, 10)}</td>
                    <td>{Math.ceil(data?.mark)?.toFixed(2)}/51</td>
                    <td>
                      {gmatResult?.values?.verbalResult?.[idx]?.mark?.toFixed(
                        2
                      )}
                      /51
                    </td>

                    <td>
                      {obj[
                        Math.ceil(gmatResult?.values?.verbalResult?.[idx]?.mark)
                      ] && obj[Math.ceil(data?.mark)]
                        ? obj[
                            Math.ceil(
                              gmatResult?.values?.verbalResult?.[idx]?.mark
                            )
                          ][Math.ceil(data?.mark)]
                        : 200}
                    </td>
                    <td>{gmatResult?.values?.awaResult?.[idx]?.mark}</td>
                    <td>
                      {gmatResult?.values?.integratedResult?.[
                        idx
                      ]?.mark?.toFixed(2)}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default GmatResult;
