import {
  CLEAR_VERBAL_DATA,
  GET_VERBAL_EXAM_PACKAGE,
  GET_VERBAL_EXAM_PACKAGE_FAIL,
  GET_VERBAL_EXAM_PACKAGE_SUCCESS,
  GET_VERBAL_QUES,
  GET_VERBAL_QUES_FAIL,
  GET_VERBAL_QUES_SUCCESS,
  GET_VERBAL_RESULT,
  GET_VERBAL_RESULT_FAIL,
  GET_VERBAL_RESULT_SUCCESS,
  STORE_VERBAL_QUES,
  SUBMIT_VERBAL_FULL_ANS,
  SUBMIT_VERBAL_FULL_ANS_FAIL,
  SUBMIT_VERBAL_FULL_ANS_SUCCESS,
  SUBMIT_VERBAL_SINGLE_ANS,
  SUBMIT_VERBAL_SINGLE_ANS_FAIL,
  SUBMIT_VERBAL_SINGLE_ANS_SUCCESS,
} from "./actionType";

export const getVerbalQues = (token, Section, difficultyLevel, PackageId) => {
  return {
    type: GET_VERBAL_QUES,
    payload: { token, Section, difficultyLevel, PackageId },
  };
};

export const getVerbalQuesSuccess = (values) => {
  return {
    type: GET_VERBAL_QUES_SUCCESS,
    payload: { values },
  };
};

export const getVerbalQuesFail = (message) => {
  return {
    type: GET_VERBAL_QUES_FAIL,
    payload: { message },
  };
};

export const getVerbalExamPackage = (token, PackageId) => {
  return {
    type: GET_VERBAL_EXAM_PACKAGE,
    payload: { token, PackageId },
  };
};

export const getVerbalExamPackageSuccess = (values) => {
  return {
    type: GET_VERBAL_EXAM_PACKAGE_SUCCESS,
    payload: { values },
  };
};

export const getVerbalExamPackageFail = (message) => {
  return {
    type: GET_VERBAL_EXAM_PACKAGE_FAIL,
    payload: { message },
  };
};

export const submitVerbalSingleAns = (obj, authToken, option) => ({
  type: SUBMIT_VERBAL_SINGLE_ANS,
  payload: { obj, authToken, option },
});

export const submitVerbalSingleAnsSuccess = (data) => ({
  type: SUBMIT_VERBAL_SINGLE_ANS_SUCCESS,
  payload: { data },
});

export const submitVerbalSingleAnsFail = (data) => ({
  type: SUBMIT_VERBAL_SINGLE_ANS_FAIL,
  payload: { data },
});
export const submitVerBalFullAns = (obj, authToken, option) => ({
  type: SUBMIT_VERBAL_FULL_ANS,
  payload: { obj, authToken, option },
});

export const submitVerBalFullAnsSuccess = (data) => ({
  type: SUBMIT_VERBAL_FULL_ANS_SUCCESS,
  payload: { data },
});

export const submitVerBalFullAnsFail = (data) => ({
  type: SUBMIT_VERBAL_FULL_ANS_FAIL,
  payload: { data },
});

export const getVerbalResult = (token, examId) => {
  return {
    type: GET_VERBAL_RESULT,
    payload: { token, examId },
  };
};

export const getVerbalResultSuccess = (values) => {
  return {
    type: GET_VERBAL_RESULT_SUCCESS,
    payload: { values },
  };
};

export const getVerbalResultFail = (message) => {
  return {
    type: GET_VERBAL_RESULT_FAIL,
    payload: { message },
  };
};
export const clearVerbalData = () => {
  return {
    type: CLEAR_VERBAL_DATA,
    payload: {},
  };
};

export const storeVerbalData = (name, data) => ({
  type: STORE_VERBAL_QUES,
  payload: { name, data },
});
