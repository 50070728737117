import React from "react";
import { Col, Container, Row, Table } from "reactstrap";

import { removeHash } from "../../../../helpers/custom/Hash";
import "./TableAnalysis.css";

const TableAnalysis = ({ question, handleChangeMcq, currentIndex }) => {
  const mainArray = question?.rowDataList;
  const subArrays = [];
  let columnLength = question?.columnNameList?.length;

  for (let i = 0; i < mainArray.length; i += columnLength) {
    const subArray = mainArray.slice(i, i + columnLength);
    subArrays.push(subArray);
  }

  return (
    <div>
      <div className="view-all-single-package-main-wrap">
        <Container>
          <h5 className="fw-bold">Integrated Reasoning</h5>
          <p className="mt-3">Table Analysis</p>

          <div className="table-analysis-question-part">
            <Row>
              <Col md={6}>
                <div className="table-analysis-ques-left">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question?.description
                        ? removeHash(question?.description)
                        : "",
                    }}
                  ></p>
                  <div className="table-analysis-ques-left-sort">
                    {/* <p>
                      Sort By:{" "}
                      <select>
                        <option defaultValue>Select...</option>
                        {[0, 1, 2, 3].map((data, idx) => (
                          <option key={idx} value={data}>
                            Select
                          </option>
                        ))}
                      </select>
                    </p> */}
                  </div>

                  <Table bordered className="table-analysis-table-header">
                    <thead>
                      <tr className="text-center">
                        {question?.columnNameList?.map((data, idx) => (
                          <th>{data}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {subArrays?.map((row, idx) => (
                        <tr>
                          {row?.map((column, idx) => (
                            <td>{column}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: question?.questionHeading
                      ? removeHash(question?.questionHeading)
                      : "",
                  }}
                ></p>
                {question?.section === 1 ? (
                  <p
                    style={{ width: "150px", gridTemplateColumns: "1fr 1fr" }}
                    className="d-grid align-items-center gap-2 mt-4"
                  >
                    <span>Yes</span> <span>No</span>
                  </p>
                ) : (
                  <p
                    style={{ width: "150px", gridTemplateColumns: "1fr 1fr" }}
                    className="d-grid align-items-center gap-2 mt-4"
                  >
                    <span>Would have</span> <span>Would have not</span>
                  </p>
                )}

                {question?.options?.map((data, idx) => (
                  <>
                    <div className="left-question-ans">
                      {question?.section === 1 ? (
                        <div
                          className="d-grid align-items-center gap-2"
                          style={{
                            width: "150px",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p>
                            <input
                              type="radio"
                              name={`question_${idx}`}
                              value="Yes"
                              onChange={(e) =>
                                handleChangeMcq({
                                  e,
                                  questionInfo: question,
                                  number: idx + 1,
                                  option: data,
                                })
                              }
                            ></input>
                          </p>
                          <p>
                            <input
                              type="radio"
                              name={`question_${idx}`}
                              value="No"
                              onChange={(e) =>
                                handleChangeMcq({
                                  e,
                                  questionInfo: question,
                                  number: idx + 1,
                                  option: data,
                                })
                              }
                            ></input>
                          </p>
                        </div>
                      ) : (
                        <div
                          className="d-grid align-items-center gap-2"
                          style={{
                            width: "150px",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          <p>
                            <input
                              type="radio"
                              name={`question_${idx}`}
                              value="Would help explain"
                              onChange={(e) =>
                                handleChangeMcq({
                                  e,
                                  questionInfo: question,
                                  number: idx + 1,
                                  option: data,
                                })
                              }
                            ></input>
                          </p>
                          <p>
                            <input
                              type="radio"
                              name={`question_${idx}`}
                              value="Would not help explain"
                              onChange={(e) =>
                                handleChangeMcq({
                                  e,
                                  questionInfo: question,
                                  number: idx + 1,
                                  option: data,
                                })
                              }
                            ></input>
                          </p>
                        </div>
                      )}

                      <p className="table-analysis-single-option">
                        {data?.option}
                      </p>
                    </div>
                  </>
                ))}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default TableAnalysis;
