import React from "react";
import { Col, Row } from "react-bootstrap";
import arrow from "./icon/arrow-right.svg";
import platform from "./icon/platform.svg";
import practice from "./icon/practice.svg";
import result from "./icon/result.svg";
import support from "./icon/support.svg";
import "./style/special-feature.scss";
const featureInfo = [
  {
    title: "Practice exam and assessment",
    option: [
      "Marked by expert teacher",
      "Question designed by expert teacher",
      "Receive detailed feedback  ",
    ],
    image: platform,
  },
  {
    title: "Self Practice Platform",
    option: [
      "Marked 100s of practice questions and answers",
      "Learn the technique with method lessons",
      "Receive Improve your grammar and vocabulary",
    ],
    image: practice,
  },
  {
    title: "Instant Result",
    option: [
      "You will get your instant result",
      "Could check your position on leadership",
      "Could compare past with past performance",
    ],
    image: result,
  },
  {
    title: "Facilitator Support",
    option: [
      "Get live review from expert facilitator",
      "Book schedule with your own time",
      "Receive Improve your grammar and vocabulary",
    ],
    image: support,
  },
];

const SpecialFeature = () => {
  return (
    <div>
      <div className="special-feature-main">
        <Row>
          <Col md={8} className="mb-5">
            <div>
              <h5>
                Xampro functions as a platform <br /> for an actual test board.
              </h5>
              <h6>
                Our most talented teachers are also writing articles to let you
                know more IELTS tips & tricks.
                <br /> Which will give you more confidence{" "}
              </h6>
            </div>
            <div className="info-button">
              {" "}
              <div>
                Get Started{" "}
                <span>
                  <img src={arrow} alt="" style={{ height: "22px" }} />
                </span>
              </div>
            </div>
          </Col>
          {featureInfo?.map((data, idx) => (
            <>
              <Col md={4} sm={12}>
                {" "}
                <div className="single-feature-section mt-3">
                  <div className="info-section-image">
                    <img src={data?.image} alt="" />
                  </div>
                  <div className="single-feature-information mt-3">
                    <h5>{data?.title}</h5>
                    {data?.option?.map((data, idx) => (
                      <li>{data}</li>
                    ))}
                  </div>
                </div>
              </Col>
            </>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default SpecialFeature;
