import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import {
  cancelExam,
  getGmatSinglePackage,
} from "../../../store/GMAT/Analytical/action";
import { clearVerbalData } from "../../../store/GMAT/Verbal/action";

const VerbalQuesInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { authToken, cancelExamLoading } = useSelector((state) => ({
    authToken: state.Login.token,
    cancelExamLoading: state.Login.cancelExamLoading,
  }));
  useEffect(() => {
    dispatch(cancelExam(authToken));
    dispatch(clearVerbalData());
  }, []);
  useEffect(() => {
    dispatch(getGmatSinglePackage(authToken, id));
  }, [id]);
  return (
    <div>
      <div className="view-all-single-package-main-wrap">
        <Container>
          <div className="text-center">
            <h5>THE GMAT TEST</h5>
            <h4 className="fw-bold mt-3">Instruction for Verbal Reasoning</h4>

            {/* <span>60 minutes | 6 questions</span> */}
            <p className="mt-3">
              There are three types of questions in the Verbal Reasoning;{" "}
            </p>
          </div>
          <div>
            <Row>
              <Col md={6}>
                <div className="mt-4 p-5">
                  <h5 className="fw-bold"> Reading Comprehension </h5>
                  <p className="mt-3">
                    In the Reading Comprehension section, you will be presented
                    with several passages, typically around 200 to 350 words in
                    length, followed by a set of questions. Business, social
                    sciences, humanities, and natural sciences are just a few
                    themes covered in the passages. The sections are intended to
                    be comparable to those that students in graduate-level
                    business and management studies would experience.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-4 p-5">
                  <h5 className="fw-bold"> Critical Reasoning </h5>
                  <p className="mt-3">
                    In the Critical Reasoning section, test takers are presented
                    with a short argument followed by a question. A brief
                    argument and a question are offered to test-takers in the
                    Critical Reasoning segment. The arguments range in length
                    from 100 to 300 words and address a wide range of subjects,
                    including business, economics, social challenges, and
                    scientific research. The arguments are intended to mimic the
                    sort of deductive thinking and critical thinking that are
                    necessary in business and management environments.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-4 p-5">
                  <h5 className="fw-bold"> Sentence Correction </h5>
                  <p className="mt-3">
                    In the Sentence Correction section, test takers are
                    presented with a sentence that may contain one or more
                    grammatical errors or stylistic flaws. The errors can
                    include problems with subject-verb agreement, verb tense,
                    pronoun usage, modifiers, parallelism, idiomatic
                    expressions, and sentence structure.
                  </p>
                </div>
              </Col>
              <div className="text-center quantitative-start-exam-btn">
                <Button
                  color="danger"
                  outline
                  onClick={() => history.push(`/gmat-service`)}
                >
                  {" "}
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() =>
                    history.push(`/gmat-service/verbal/exam/${id}`)
                  }
                >
                  Start Exam
                </Button>
              </div>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default VerbalQuesInfo;
