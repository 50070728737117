import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import resultImg from "../../../assets/images/ResultIcon.svg";
import { getVerbalResult } from "../../../store/GMAT/Verbal/action";
const AnalyticalResult = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    authtoken,
    verbalResult,
    verbalResultLoading,
    QuantitativeExamPackage,
  } = useSelector((state) => ({
    authtoken: state.Login.token,

    QuantitativeExamPackage:
      state.QuantitativeQuestionReducer.QuantitativeExamPackage,
    verbalResult: state.verbalQuestionReducer.verbalResult,
    verbalResultLoading: state.verbalQuestionReducer.verbalResultLoading,
  }));
  const ExamId = localStorage.getItem("verBalExamId");
  console.log("QuantitativeExamPackage", QuantitativeExamPackage);
  useEffect(() => {
    if (ExamId) {
      dispatch(getVerbalResult(authtoken, ExamId));
    }
  }, []);
  console.log("verbalResult", verbalResult);
  return (
    <div>
      <div>
        <div className="empty-package-area-wrap">
          <div>
            <img src={resultImg} alt="" />
            <h5>You have successfully submitted your exam</h5>
            <p className="mt-3">
              Result is under process
              {/* <span className="fw-bold">{verbalResult?.mark}</span> */}
            </p>
          </div>
        </div>
      </div>
      <div className="mb-5 p-5">
        {/* <Table hover responsive="md" className="mb-5">
          <thead>
            <tr>
              <th>
                <span>Question no</span>
              </th>
              <th>
                <span>Right answer</span>
              </th>
              <th>
                <span>Your answer</span>
              </th>
              <th>
                <span>Status</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {verbalResult?.answer?.map((ans, idx) => (
              <tr>
                <td>
                  <span className="ques-id-serial">{ans?.serial}</span>
                </td>
                <td>{ans?.rightAnswer[0]}</td>
                <td>{ans?.answer}</td>
                <td>
                  {" "}
                  {ans.mark === 1 ? (
                    <span className="correct-ans">
                      <i className="bx bx-check"></i>
                    </span>
                  ) : (
                    <span className="false-ans">
                      <i className="bx bx-x"></i>
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table> */}
        <div className="text-center">
          {" "}
          <Button
            className="text-center "
            outline
            color="warning"
            onClick={() => history.push("/gmat-service")}
          >
            {" "}
            Go to Package
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AnalyticalResult;
