import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getVerbalExamPackage,
  getVerbalQues,
  storeVerbalData,
} from "../../../store/GMAT/Verbal/action";
import SingleVerbalQuestionList from "./SingleVerbalQuestionList";
import VerbalFooter from "./VerbalFooter";

const VerbalQues = () => {
  const dispatch = useDispatch();
  const [object, setObject] = useState({});
  const { id } = useParams();

  //   const { assessmentId, examType, currentIndex } = useParams();
  const {
    authtoken,
    verbalQues,
    verbalQuesLoading,
    verBalIndex,
    verbalExamPackage,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    verbalQues: state.verbalQuestionReducer.verbalQues,
    verBalIndex: state.verbalQuestionReducer.verBalIndex,
    verbalQuesLoading: state.verbalQuestionReducer.verbalQuesLoading,
    QuantitativeQuesLoading:
      state.QuantitativeQuestionReducer.QuantitativeQuesLoading,
    ans: state.QuantitativeQuestionReducer.ans,
    currentIndex: state.QuantitativeQuestionReducer.currentIndex,
    verbalExamPackage: state.verbalQuestionReducer.verbalExamPackage,
  }));
  localStorage.setItem("verBalExamId", verbalExamPackage?.values);
  useEffect(() => {
    dispatch(getVerbalQues(authtoken, 1, 3, id));
  }, []);
  useEffect(() => {
    dispatch(getVerbalExamPackage(authtoken, id));
  }, []);

  //   useEffect(() => {
  //     const debounce = setTimeout(() => {
  //       if (object?.answer) {
  //         submitData(object);
  //         setObject({});
  //       }
  //     }, 300);
  //     return () => clearTimeout(debounce);

  //   }, [object?.answer]);

  const handleStoreAnswer = ({ e, data, number, difficultyCheck }) => {
    console.log("data difficult", data, difficultyCheck);
    const { value } = e.target;
    const optionArray = [value];
    const serial = number - 1;
    const body = {
      serial: number,
      readingSerial: number,
      answer: value,
      difficultyLevel: difficultyCheck,
      subQuestionId: data?._id,
    };
    dispatch(storeVerbalData("singleAns", body));
    submitData(body);
  };

  const submitData = (data) => {
    console.log("hellodxzc", data);
    // const options = {
    //   type: examType,
    //   assessmentId,
    //   answerId,
    //   history,
    //   questionId: question?._id,
    // };
    // // console.log(`options`, options, data);
    // dispatch(submitAptitudeAnsOneByOne({ data, options }));
  };

  return (
    <React.Fragment>
      <div className="ques-right-part-container">
        <SingleVerbalQuestionList
          handleChangeMcq={handleStoreAnswer}
          questions={verbalQues}
          questionNumber={verBalIndex + 1}
        />
      </div>
      <VerbalFooter />
    </React.Fragment>
  );
};

export default VerbalQues;
