import _clone from "lodash/clone";
import _escapeRegExp from "lodash/escapeRegExp";
import React from "react";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import Loader from "../../../components/Atoms/Loader";
import { removeHash } from "../../../helpers/custom/Hash";
import "./VerbalQuestion.css";
const SingleVerbalQuestionList = ({
  handleChangeMcq,
  questions,
  questionNumber,
  readingComprehension,
  sentenceEquivalence,
  textComprehension,
}) => {
  const { authtoken, verbalQues, verbalQuesLoading, singleAns, verBalIndex } =
    useSelector((state) => ({
      authtoken: state.Login.token,
      verbalQues: state.verbalQuestionReducer.verbalQues,
      verbalQuesLoading: state.verbalQuestionReducer.verbalQuesLoading,
      singleAns: state.verbalQuestionReducer.singleAns,
      verBalIndex: state.verbalQuestionReducer.verBalIndex,
      readingComprehension: state.verbalQuestionReducer.readingComprehension,
      sentenceEquivalence: state.verbalQuestionReducer.sentenceEquivalence,
      textComprehension: state.verbalQuestionReducer.textComprehension,
    }));
  console.log("questions", questions);
  const checkImage = (value) => {
    const tags = value?.match(/img@&#\[(.*?)\]#&@img+/gi) || [];
    if (tags.length === 1) {
      return true;
    } else {
      return false;
    }
  };

  const getImageUrl = (value) => {
    let displayText = _clone(value);
    const tags = value?.match(/img@&#\[(.*?)\]#&@img+/gi) || [];
    if (tags?.length) {
      tags.forEach((myTag, idx) => {
        const tagData = myTag?.slice(7, -7);
        displayText = displayText?.replace(
          new RegExp(_escapeRegExp(myTag), "gi"),
          tagData
        );
      });
    }
    return displayText;
  };

  return (
    <div>
      <div>
        <div className="view-all-single-package-main-wrap">
          {verbalQuesLoading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : (
            <Container>
              <h5 className="fw-bold">Verbal Reasoning</h5>
              <p className="mt-3">
                {verbalQues?.section === 1
                  ? "Reading comprehension"
                  : verbalQues?.section === 2
                  ? "Sentence Correction"
                  : "Critical Reasoning"}
              </p>
              <div className="quantitative-reasoning-question">
                {verbalQues?.section === 1 ? (
                  <div>
                    {/* <div className="ques-header-serial">
                      <h6 style={{ position: "initial" }}>{questionNumber}</h6>
                      <h5 className="fw-bold">Description</h5>
                    </div> */}
                    <div className="verbal-ques-description">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: verbalQues?.description
                            ? removeHash(verbalQues?.description)
                            : "",
                        }}
                      ></p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div>
                  <div className="ques-header-question-part">
                    {/* {verbalQues?.section === 3 && (
                      <p>{verbalQues?.questionTitle}</p>
                    )} */}
                    <p
                      className="fw-bold mt-3"
                      dangerouslySetInnerHTML={{
                        __html: removeHash(
                          verbalQues?.section === 1
                            ? verbalQues?.verbalReadingMcq?.[verBalIndex]?.q ||
                                ""
                            : verbalQues?.q || ""
                        ),
                      }}
                    ></p>
                  </div>
                  {verbalQues?.section === 1 ? (
                    <>
                      {questions?.verbalReadingMcq?.[verBalIndex]?.options?.map(
                        (data, idx) => (
                          <>
                            <div className="quantitative-single-ques-single-option">
                              <input
                                type="radio"
                                name={`question${questions?.verbalReadingMcq?.[verBalIndex]}`}
                                id={`exampleRadios${questionNumber + idx}`}
                                checked={data === singleAns?.answer}
                                value={data}
                                onChange={(e) =>
                                  handleChangeMcq({
                                    e,
                                    data: questions?.verbalReadingMcq?.[
                                      verBalIndex
                                    ],

                                    number: questionNumber,
                                    difficultyCheck: questions?.difficultyLevel,
                                  })
                                }
                              />
                               {" "}
                              <label
                                htmlFor={`exampleRadios${questionNumber + idx}`}
                              >
                                {checkImage(data) ? (
                                  <img
                                    style={{
                                      height: "35px",
                                      objectFit: "cover",
                                    }}
                                    src={getImageUrl(data)}
                                    alt="img"
                                  />
                                ) : (
                                  data
                                )}
                              </label>
                              <br />
                            </div>
                          </>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {verbalQues?.options?.map((data, idx) => (
                        <>
                          <div className="quantitative-single-ques-single-option">
                            <input
                              type="radio"
                              name={`question${questions?.values?.questionNumber}`}
                              id={`exampleRadios${questionNumber + idx}`}
                              checked={data === singleAns?.answer}
                              value={data}
                              onChange={(e) =>
                                handleChangeMcq({
                                  e,
                                  data: questions,
                                  number: questionNumber,
                                  difficultyCheck: questions?.difficultyLevel,
                                })
                              }
                            />
                             {" "}
                            <label
                              htmlFor={`exampleRadios${questionNumber + idx}`}
                            >
                              {checkImage(data) ? (
                                <img
                                  style={{
                                    height: "35px",
                                    objectFit: "cover",
                                  }}
                                  src={getImageUrl(data)}
                                  alt="img"
                                />
                              ) : (
                                data
                              )}
                            </label>
                            <br />
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleVerbalQuestionList;
