/* eslint-disable eqeqeq */
import { Box } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import packageProfile from "../../assets/images/Frame.svg";
import Loader from "../../components/Atoms/Loader";
import { imageBaseUrl } from "../../helpers/api_helper";
import { getFacilitatorReview } from "../../store/Facilitator/actions";
import { getSinglePackage } from "../../store/Package/actions";
import { storeQuestion } from "../../store/Question/actions";
import { getResult, getResultSuccess } from "../../store/Result/actions";
import "./ResultPage.scss";
import ReadingAns from "./ViewAnswer";
import packageImage from "./img/01-3.png";
// import ViewListeningResult from "./ViewListening/ViewListening";
import { Modal } from "react-bootstrap";
import ViewListeningResult from "./ViewListening/ViewListening";
import ReviewAnswerList from "./Writing/ReviewAnswerList";

// const score = [
//   9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1, 0,
// ];
const imageHelper = (data) => {
  switch (data?.toLowerCase()?.trim()) {
    case "reading":
      return "reading.svg";
    case "speaking":
      return "speaking.svg";
    case "writing":
      return "writing.svg";
    case "listening":
      return "listening.svg";
    default:
      return "reading.svg";
  }
};

const Result = ({ history }) => {
  const { id, packageId, type } = useParams();
  const [detailShow, setDetailShow] = useState(false);
  const dispatch = useDispatch();
  const {
    authToken,
    results,
    singlePackage,
    profile,
    loading,
    questions,
    loginId,
    listeningQuestion,
    facilitatorReview,

    FacilitatorReviewLoading,
  } = useSelector((state) => ({
    authToken: state.Login.token,
    results: state.resultReducer.results,
    loading: state.resultReducer.loading,
    singlePackage: state.PackageReducer.singlePackage?.singlePackage,
    singlePackageLoading: state.PackageReducer.singlePackageLoading,
    profile: state.Profile?.data,
    currentIndex: state.QuesReducer.currentIndex,
    questions: state.QuesReducer.questions,
    listeningQuestion:
      state.resultReducer.results?.data?.setId?.listeningAudioModel,
    facilitatorReview: state.facilitator.facilitatorReview,
    FacilitatorReviewLoading: state.facilitator.FacilitatorReviewLoading,
    loginId: state.Login.id,
  }));

  useEffect(() => {
    dispatch(getFacilitatorReview(authToken, id, loginId, type));
  }, []);

  useEffect(() => {
    dispatch(getResultSuccess({}));
    dispatch(getSinglePackage(authToken, packageId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let url = "";
    switch (type) {
      case "reading":
        url = `/package/get-answer/${id}`;
        break;
      case "listening":
        url = `/package/get-answer/listening/${id}`;
        break;
      case "writing":
        url = `/package/writting/student/showmark/${id}`;
        break;
      default:
        break;
    }
    dispatch(getResult(authToken, url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (type === 'reading') {
    //   dispatch(getReadingQues(authToken, id, true, packageId));
    // }
    return () => {
      dispatch(getResultSuccess({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ansData = useMemo(() => {
    let answer = [];
    for (const obj in results?.result) {
      answer.push({
        questionNumber: obj,
        ...results?.result[obj],
        view: false,
      });
    }
    return answer;
  }, [results]);

  const getData = () => {
    if (Object?.keys(results)?.length === 0 || results?.result === null) {
      return null;
    }
    switch (type) {
      case "reading":
        return <ReadingAns ans={results?.result} />;
      case "listening":
        return <ViewListeningResult ans={results?.result} />;
      default:
        return null;
    }
  };

  function scroll(element, number) {
    const ele = document.getElementById(element);
    let question;
    if (type === "reading") {
      question = questions;
    } else if (type === "listening") {
      question = listeningQuestion;
    }
    let passage = 0;
    question?.forEach((data, index) => {
      let questionPosition = 0;
      if (index > 0) {
        for (let i = 0; i < index; i++) {
          const previous = question?.[i];
          const lastQuestion =
            previous?.questions?.[previous?.questions?.length - 1];
          questionPosition +=
            lastQuestion?.Que?.question?.[
              lastQuestion?.Que?.question?.length - 1
            ]?.questionNumber;
        }
      }
      data?.questions?.forEach((ques) => {
        ques?.Que?.question?.forEach((list) => {
          const questionNum = list?.questionNumber + questionPosition;
          if (questionNum == number) {
            passage = index;
          }
        });
      });
    });
    dispatch(storeQuestion("currentIndex", passage));

    ele?.scrollIntoView();
    // window.scroll({ top: ele?.offsetTop - offset, left: 0, behavior: 'smooth' });
    // } else {
    //   ele?.scrollIntoView();

    // }
  }
  const handleReview = () => {
    setDetailShow(true);
  };
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return packageImage;
  };
  const getPhotoUrl1 = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return packageProfile;
  };
  return (
    <React.Fragment>
      <div className="result-main-area">
        <Container className="g-0">
          <div className="result-inner-area">
            {/* <button onClick={() => scroll('target1')}>go</button> */}
            <h3>
              <span onClick={() => history.goBack()}>
                <i className="bx bx-arrow-back"></i>
              </span>
              Your Result
            </h3>
            {loading ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="result-main-area-inner-box">
                {type === "listening" ||
                type === "reading" ||
                (type === "writing" && results?.status === 2) ? (
                  <React.Fragment>
                    <h4>Package</h4>
                    <div className="course-result--profile-area first-result-box">
                      <Row>
                        <Col lg={3}>
                          <div
                            className="exem-course-result-package rounded-5 m-0"
                            style={{
                              boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.08)",
                              overflow: "hidden",
                            }}
                          >
                            <Card className="course-result--profile-box rounded-5 m-0">
                              <div
                                className="card-img-top"
                                style={{
                                  backgroundImage: `url(${getPhotoUrl(
                                    singlePackage?.photo
                                  )})`,
                                }}
                              ></div>
                              {/* <Card.Img
                                variant="top"
                                src={
                                  singlePackage?.photo
                                    ? imageBaseUrl + singlePackage?.photo
                                    : packageImage
                                }
                              /> */}
                              <Card.Body
                                style={{
                                  borderRadius: "0 0 10px 10px",
                                  overflow: "hidden",
                                }}
                              >
                                <div className="course-review-area-exam-page d-flex">
                                  <Box
                                    component="fieldset"
                                    borderColor="transparent"
                                  >
                                    <span>
                                      <Rating
                                        name="simple-controlled"
                                        precision={0.1}
                                        defaultValue={
                                          singlePackage?.aveRatings || 0
                                        }
                                        readOnly
                                      />
                                    </span>
                                  </Box>
                                  <span style={{ color: "black" }}>
                                    {singlePackage?.aveRatings}(
                                    {singlePackage?.ratings?.length})
                                  </span>
                                </div>
                                <Card.Title
                                  className="course-review-area-exam-course-title"
                                  style={{
                                    fontSize: "18px",
                                  }}
                                >
                                  {singlePackage?.packageName}
                                </Card.Title>
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                        <Col lg={9}>
                          <div className="exam-result-profile-feedback-view ps-3">
                            <div className="exam-result-profile---content">
                              <img
                                className="rounded-circle result-profile-page"
                                src={getPhotoUrl1(
                                  profile?.getData?.getProfile?.photo
                                )}
                                alt=""
                              />

                              <span>
                                {profile?.getData?.getProfile?.fullName}
                              </span>
                            </div>
                            <div
                              className={
                                type === "writing"
                                  ? "d-flex justify-content-center text-center gap-5 border-bottom py-4"
                                  : "exam-result-feedback---content"
                              }
                            >
                              {type === "writing" ? null : (
                                <div className="exam-result-feedback---item">
                                  <p>Answers</p>
                                  <h6>
                                    {results?.rightAnswer}
                                    /40
                                  </h6>
                                </div>
                              )}
                              <div className="exam-result-feedback---item">
                                <p>Your Band Score</p>
                                <h6>
                                  {type === "writing"
                                    ? Math.ceil(results?.mark)
                                    : Math.ceil(results?.mark)}
                                </h6>
                              </div>
                              <div className="exam-result-feedback---item">
                                <p>Time Spent</p>
                                <h6>{results?.timeTaken || 0} min</h6>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="exam-ques-your-position">
                            {/* <p>Review Brand Score</p>
                      <ul>
                        {score.map((list, idx, item) => {
                          return (
                            <li
                              key={idx}
                              className={
                                handleActiveClass(list, idx, item) === list
                                  ? 'your-exam-answer'
                                  : ''
                              }
                            >
                              {list}
                            </li>
                          );
                        })}
                      </ul> */}
                          </div>
                        </Col>
                      </Row>
                      <div className="pt-2 d-flex align-items-center">
                        <img
                          src={require(`./img/${imageHelper(type)}`).default}
                          alt="set"
                          width={"40px"}
                        />
                        Set : <strong>{results?.setId?.setName}</strong>
                      </div>
                      <div className="text-end">
                        <Button
                          className="btn btn-primary"
                          onClick={() => handleReview()}
                        >
                          View Review
                        </Button>
                      </div>
                    </div>
                    <h4>Your answers keys</h4>
                  </React.Fragment>
                ) : null}
                {type !== "writing" ? (
                  <div>
                    {loading ? (
                      <div className="text-center">
                        <Loader />
                      </div>
                    ) : (
                      <div className="course-result--profile-area">
                        <Table borderless hover responsive="md">
                          <thead>
                            <tr>
                              <th>
                                <span>Question no</span>
                              </th>
                              <th>
                                <span>Right answer</span>
                              </th>
                              <th>
                                <span>Your answer</span>
                              </th>
                              <th>
                                <span>Status</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {ansData?.map((ans, idx) => (
                              <tr
                                onClick={() =>
                                  scroll(
                                    `question${ans?.questionNumber}`,
                                    ans?.questionNumber
                                  )
                                }
                              >
                                <td>
                                  <span className="ques-id-serial">
                                    {ans?.questionNumber}
                                  </span>
                                </td>
                                <td>
                                  {ans?.rightAnswer?.map(
                                    (list, index) =>
                                      `${list} ${index === 0 ? "" : ","} `
                                  )}
                                </td>
                                <td>
                                  {ans?.answer?.map(
                                    (list, index) =>
                                      `${list} ${index === 0 ? "" : ","}`
                                  )}
                                </td>
                                <td>
                                  {ans.mark === 1 ? (
                                    <span className="correct-ans">
                                      <i className="bx bx-check"></i>
                                    </span>
                                  ) : (
                                    <span className="false-ans">
                                      <i className="bx bx-x"></i>
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                    <h4 id="target1" className="mt-5">
                      Review your answers and explanation
                    </h4>
                    {/* ans preview */}
                    {loading ? (
                      <div className="text-center">
                        <Loader />
                      </div>
                    ) : (
                      getData()
                    )}
                  </div>
                ) : (
                  <ReviewAnswerList results={results} />
                )}
              </div>
            )}
          </div>
          <Modal
            size="md"
            show={detailShow}
            onHide={() => setDetailShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <h6>Review Details</h6>
            </Modal.Header>
            <Modal.Body style={{ padding: "3px 25px" }}></Modal.Body>
            <div>
              <Accordion flush className="result-page-popup-accordion">
                {facilitatorReview?.values?.getFacilitatorReview?.review?.map(
                  (review, idx) => (
                    <Accordion.Item eventKey={idx}>
                      <Accordion.Header>{review?.section}</Accordion.Header>
                      <Accordion.Body>{review?.review}</Accordion.Body>
                    </Accordion.Item>
                  )
                )}
              </Accordion>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Result);
