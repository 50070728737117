import React from "react";

const QuantitativeIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.01953 11.4844H10.1211V12.3047H6.01953V11.4844Z"
        fill="#0052CC"
      />
      <path
        d="M10.1211 1.64062V0H4.37891V1.64062H1.91797V14H12.582V1.64062H10.1211ZM5.19922 0.820312H9.30078V1.64062H5.19922V0.820312ZM11.7617 13.1797H2.73828V2.46094H11.7617V13.1797Z"
        fill="#0052CC"
      />
      <path
        d="M4.37891 11.4844H5.19922V12.3047H4.37891V11.4844Z"
        fill="#0052CC"
      />
      <path
        d="M6.01953 9.84375H10.1211V10.6641H6.01953V9.84375Z"
        fill="#0052CC"
      />
      <path
        d="M4.37891 9.84375H5.19922V10.6641H4.37891V9.84375Z"
        fill="#0052CC"
      />
      <path
        d="M7.25 9.02344C8.83312 9.02344 10.1211 7.73547 10.1211 6.15234C10.1211 4.56922 8.83312 3.28125 7.25 3.28125C5.66688 3.28125 4.37891 4.56922 4.37891 6.15234C4.37891 7.73547 5.66688 9.02344 7.25 9.02344ZM9.25952 5.74219H7.66016V4.14282C8.46253 4.30634 9.09598 4.93981 9.25952 5.74219ZM6.83984 4.14282V6.5625H9.25952C9.06899 7.49744 8.24039 8.20312 7.25 8.20312C6.1192 8.20312 5.19922 7.28314 5.19922 6.15234C5.19922 5.16198 5.90491 4.33338 6.83984 4.14282Z"
        fill="#0052CC"
      />
    </svg>
  );
};

export default QuantitativeIcon;
