import {
  CANCEL_EXAM,
  CANCEL_EXAM_FAIL,
  CANCEL_EXAM_SUCCESS,
  GET_ANALYTICAL_EXAM_PACKAGE,
  GET_ANALYTICAL_EXAM_PACKAGE_FAIL,
  GET_ANALYTICAL_EXAM_PACKAGE_SUCCESS,
  GET_ANALYTICAL_QUES,
  GET_ANALYTICAL_QUES_FAIL,
  GET_ANALYTICAL_QUES_SUCCESS,
  GET_GMAT_RESULT,
  GET_GMAT_RESULT_FAIL,
  GET_GMAT_RESULT_SUCCESS,
  GET_SINGLE_GMAT_PACKAGE,
  GET_SINGLE_GMAT_PACKAGE_FAIL,
  GET_SINGLE_GMAT_PACKAGE_SUCCESS,
  STORE_ANALYTICAL_QUES,
  SUBMIT_ANALYTICAL_FULL_ANS,
  SUBMIT_ANALYTICAL_FULL_ANS_FAIL,
  SUBMIT_ANALYTICAL_FULL_ANS_SUCCESS,
  SUBMIT_ANALYTICAL_SINGLE_ANS,
  SUBMIT_ANALYTICAL_SINGLE_ANS_FAIL,
  SUBMIT_ANALYTICAL_SINGLE_ANS_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  loading: true,
  error: "",
  data: [],
  adding: false,
  AnalyticalQues: [],
  AnalyticalQuesLoading: false,
  AnalyticalExamPackage: [],
  AnalyticalExamPackageLoading: false,
  cancelExamLoading: true,
  cancelExam: [],
  gmatResult: [],
  gmatResultLoading: true,
  singleGmatPackage: [],
  singleGmatPackageLoading: true,
  singleAns: {},
};

const AnalyticalQuesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANALYTICAL_QUES:
      return {
        ...state,
        AnalyticalQuesLoading: true,
      };
    case GET_ANALYTICAL_QUES_SUCCESS:
      return {
        ...state,
        AnalyticalQuesLoading: false,
        AnalyticalQues: action.payload?.values,
      };
    case GET_ANALYTICAL_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        AnalyticalQuesLoading: false,
      };
    case GET_ANALYTICAL_EXAM_PACKAGE:
      return {
        ...state,
        AnalyticalExamPackageLoading: true,
      };
    case GET_ANALYTICAL_EXAM_PACKAGE_SUCCESS:
      return {
        ...state,
        AnalyticalExamPackageLoading: false,
        AnalyticalExamPackage: action.payload,
      };
    case GET_ANALYTICAL_EXAM_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        AnalyticalExamPackageLoading: false,
      };
    case SUBMIT_ANALYTICAL_SINGLE_ANS:
      return {
        ...state,
        adding: true,
      };

    case SUBMIT_ANALYTICAL_SINGLE_ANS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        adding: false,
      };

    case SUBMIT_ANALYTICAL_SINGLE_ANS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        adding: false,
      };
    case SUBMIT_ANALYTICAL_FULL_ANS:
      return {
        ...state,
        adding: true,
      };

    case SUBMIT_ANALYTICAL_FULL_ANS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        adding: false,
      };

    case SUBMIT_ANALYTICAL_FULL_ANS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        adding: false,
      };

    case CANCEL_EXAM:
      return {
        ...state,
        cancelExamLoading: true,
      };
    case CANCEL_EXAM_SUCCESS:
      return {
        ...state,
        cancelExamLoading: false,
        cancelExam: action.payload,
      };
    case CANCEL_EXAM_FAIL:
      return {
        ...state,
        error: action.payload,
        cancelExamLoading: false,
      };

    case GET_GMAT_RESULT:
      return {
        ...state,
        gmatResultLoading: true,
      };
    case GET_GMAT_RESULT_SUCCESS:
      return {
        ...state,
        gmatResultLoading: false,
        gmatResult: action.payload,
      };
    case GET_GMAT_RESULT_FAIL:
      return {
        ...state,
        error: action.payload,
        cancelExamLoading: false,
      };
    case GET_SINGLE_GMAT_PACKAGE:
      return {
        ...state,
        singleGmatPackageLoading: true,
      };
    case GET_SINGLE_GMAT_PACKAGE_SUCCESS:
      return {
        ...state,
        singleGmatPackageLoading: false,
        singleGmatPackage: action.payload,
      };
    case GET_SINGLE_GMAT_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        singleGmatPackageLoading: false,
      };
    case STORE_ANALYTICAL_QUES:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };

    default:
      return state;
  }
};

export default AnalyticalQuesReducer;
