export const GET_VERBAL_QUES = "GET_VERBAL_QUES";
export const GET_VERBAL_QUES_SUCCESS = "GET_VERBAL_QUES_SUCCESS";
export const GET_VERBAL_QUES_FAIL = "GET_VERBAL_QUES_FAIL";

export const GET_VERBAL_EXAM_PACKAGE = "GET_VERBAL_EXAM_PACKAGE";
export const GET_VERBAL_EXAM_PACKAGE_SUCCESS =
  "GET_VERBAL_EXAM_PACKAGE_SUCCESS";
export const GET_VERBAL_EXAM_PACKAGE_FAIL = "GET_VERBAL_EXAM_PACKAGE_FAIL";

export const SUBMIT_VERBAL_SINGLE_ANS = "SUBMIT_VERBAL_SINGLE_ANS";
export const SUBMIT_VERBAL_SINGLE_ANS_SUCCESS =
  "SUBMIT_VERBAL_SINGLE_ANS_SUCCESS";
export const SUBMIT_VERBAL_SINGLE_ANS_FAIL = "SUBMIT_VERBAL_SINGLE_ANS_FAIL";

export const SUBMIT_VERBAL_FULL_ANS = "SUBMIT_VERBAL_FULL_ANS";
export const SUBMIT_VERBAL_FULL_ANS_SUCCESS = "SUBMIT_VERBAL_FULL_ANS_SUCCESS";
export const SUBMIT_VERBAL_FULL_ANS_FAIL = "SUBMIT_VERBAL_FULL_ANS_FAIL";

export const GET_VERBAL_RESULT = "GET_VERBAL_RESULT";
export const GET_VERBAL_RESULT_SUCCESS = "GET_VERBAL_RESULT_SUCCESS";
export const GET_VERBAL_RESULT_FAIL = "GET_VERBAL_RESULT_FAIL";

export const CLEAR_VERBAL_DATA = "CLEAR_VERBAL_DATA";

export const STORE_VERBAL_QUES = "STORE_VERBAL_QUES";
