import React from "react";
import { Col, Container, Row, Table } from "reactstrap";

import { Tab } from "bootstrap";
import { useState } from "react";
import { Tabs } from "react-bootstrap";
import { removeHash } from "../../../../helpers/custom/Hash";
import "./MultiSource.css";

const MultiSouce = ({ question, handleChangeMcq, currentIndex }) => {
  const [key, setKey] = useState("home");
  console.log("question", question);
  // const mainArray = question?.rowDataList;
  // const subArrays = [];
  // let columnLength = question?.columnNameList?.length;

  // for (let i = 0; i < mainArray.length; i += columnLength) {
  //   const subArray = mainArray.slice(i, i + columnLength);
  //   subArrays.push(subArray);
  // }

  return (
    <div>
      <div className="view-all-single-package-main-wrap">
        <Container>
          <h5 className="fw-bold">Integrated Reasoning</h5>
          <p className="mt-3">Multi source reasoning</p>

          <div className="table-analysis-question-part">
            <Row>
              <Col md={6}>
                <div className="table-analysis-ques-left">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="multiSource-tab mb-3"
                  >
                    {question?.multiSource?.map((data, idx) => (
                      <Tab eventKey={data?.heading} title={data?.heading}>
                        <p>{data?.passage}</p>
                      </Tab>
                    ))}
                  </Tabs>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: question?.description
                        ? removeHash(question?.description)
                        : "",
                    }}
                  ></p>
                  <div className="table-analysis-ques-left-sort">
                    {/* <p>
                      Sort By:{" "}
                      <select>
                        <option defaultValue>Select...</option>
                        {[0, 1, 2, 3].map((data, idx) => (
                          <option key={idx} value={data}>
                            Select
                          </option>
                        ))}
                      </select>
                    </p> */}
                  </div>

                  <Table bordered className="table-analysis-table-header">
                    <thead>
                      <tr className="text-center">
                        {question?.columnNameList?.map((data, idx) => (
                          <th>{data}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {subArrays?.map((row, idx) => (
                        <tr>
                          {row?.map((column, idx) => (
                            <td>{column}</td>
                          ))}
                        </tr>
                      ))} */}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col md={5}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: question?.questionHeading
                      ? removeHash(question?.questionHeading)
                      : "",
                  }}
                ></p>
                <p className="mt-4">
                  Yes <span className="ms-4">No</span>
                </p>
                {question?.options?.map((data, idx) => (
                  <>
                    <div className="left-question-ans-multi-source">
                      <p>
                        <input
                          type="radio"
                          name={`question_${idx}`}
                          value="Yes"
                          onChange={(e) =>
                            handleChangeMcq({
                              e,
                              questionInfo: question,
                              number: idx + 1,
                              option: data,
                            })
                          }
                        ></input>
                      </p>
                      <p>
                        <input
                          type="radio"
                          name={`question_${idx}`}
                          value="No"
                          onChange={(e) =>
                            handleChangeMcq({
                              e,
                              questionInfo: question,
                              number: idx + 1,
                              option: data,
                            })
                          }
                        ></input>
                      </p>

                      <p className="table-analysis-single-option">
                        {data?.option}
                      </p>
                    </div>
                  </>
                ))}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MultiSouce;
