import { call, put, takeEvery } from "redux-saga/effects";
import { getData, patchData } from "../../../helpers/backend_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  getQuantitativeExamPackageFail,
  getQuantitativeExamPackageSuccess,
  getQuantitativeQues,
  getQuantitativeQuesFail,
  getQuantitativeQuesSuccess,
  getQuantitativeResultFail,
  getQuantitativeResultSuccess,
  storeQuantitativeData,
  submitQuantitativeFullAns,
  submitQuantitativeFullAnsFail,
  submitQuantitativeFullAnsSuccess,
  submitQuantitativeSingleAnsFail,
  submitQuantitativeSingleAnsSuccess,
} from "./action";
import {
  GET_QUANTITATIVE_EXAM_PACKAGE,
  GET_QUANTITATIVE_QUES,
  GET_QUANTITATIVE_RESULT,
  SUBMIT_QUANTITATIVE_FULL_ANS,
  SUBMIT_QUANTITATIVE_SINGLE_ANS,
} from "./actionType";

function* onGetQuantitativeQues({
  payload: { token, Section, difficultyLevel, PackageId },
}) {
  try {
    console.log("HELLLLLLLLLLO");
    const url = `package/get-quantative-question/${Section}/${difficultyLevel}/${PackageId}`;
    const response = yield call(getData, url, token);
    yield put(getQuantitativeQuesSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getQuantitativeQuesFail(message));
    toaster("error", message);
  }
}

function* onGetQuantitativeExamPackage({ payload: { token, PackageId } }) {
  try {
    const url = `package/start-quantative-exam/${PackageId}`;
    const response = yield call(getData, url, token);
    yield put(getQuantitativeExamPackageSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getQuantitativeExamPackageFail(message));
    toaster("error", message);
  }
}
function* onAddQuantitativeSingleAns({ payload: { obj, authToken, option } }) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, obj, option);
  try {
    const url = `package/quantative-answer-one-by-one/${
      option?.examPackageId
    }/${option?.totalDataSufficiency > 0 ? "1" : "2"}/${option?.questionId}`;
    const response = yield call(patchData, url, obj, authToken);
    console.log("response", response);

    if (response?.status === "succes") {
      yield put(storeQuantitativeData("singleAns", {}));

      // right answer and difficulty check for every question
      let difficultValue;
      let answerCheck = response?.answerResponse;
      let difficultyCheck = response?.difficultyLevel;
      if (difficultyCheck === 1 && answerCheck === 1) {
        difficultValue = 2;
      }
      if (difficultyCheck === 1 && answerCheck === 0) {
        difficultValue = 1;
      }
      if (difficultyCheck === 2 && answerCheck === 1) {
        difficultValue = 3;
      }
      if (difficultyCheck === 2 && answerCheck === 0) {
        difficultValue = 1;
      }
      if (difficultyCheck === 3 && answerCheck === 1) {
        difficultValue = 3;
      }
      if (difficultyCheck === 3 && answerCheck === 0) {
        difficultValue = 2;
      }

      // check section for every question
      const currentDataSufficiency =
        option?.totalDataSufficiency > 0 ? option?.totalDataSufficiency - 1 : 0;

      let currentProblemSolving = option?.totalProblemSolving;
      if (currentDataSufficiency < 1) {
        currentProblemSolving = option?.totalProblemSolving - 1;
      }
      let section = currentDataSufficiency < 1 ? "2" : "1";

      yield put(submitQuantitativeSingleAnsSuccess(response?.message));
      yield put(
        storeQuantitativeData("totalDataSufficiency", currentDataSufficiency)
      );
      yield put(
        storeQuantitativeData("totalProblemSolving", currentProblemSolving)
      );
      if (option?.seFullAns !== true) {
        yield put(
          getQuantitativeQues(
            authToken,
            section,
            difficultValue,
            option?.packageId
          )
        );
      }
      if (option?.seFullAns === true) {
        yield put(
          submitQuantitativeFullAns({ examStatus: 3 }, authToken, option)
        );
      }
    }

    // toaster("success", `FAQ type added Successfully`);
    // history.push("/faqType");
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(submitQuantitativeSingleAnsFail(message));
  }
}
function* onAddQuantitativeFullAns({ payload: { obj, authToken, option } }) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, obj);
  try {
    const url = `package/quantative-answer-submit/${option?.examPackageId}`;
    const response = yield call(patchData, url, obj, authToken);
    option?.setEndExam(false);
    option?.history.push("/quan-result");
    yield put(submitQuantitativeFullAnsSuccess(response?.message));
    yield put(storeQuantitativeData("currentIndex", 0));
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(submitQuantitativeFullAnsFail(message));
  }
}

function* onGetQuantitativeResult({ payload: { token, examId } }) {
  try {
    const url = `package/quantative-exam-result/${examId}`;
    const response = yield call(getData, url, token);
    yield put(getQuantitativeResultSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getQuantitativeResultFail(message));
    toaster("error", message);
  }
}
function* QuantitativeQuesSaga() {
  yield takeEvery(GET_QUANTITATIVE_QUES, onGetQuantitativeQues);
  yield takeEvery(GET_QUANTITATIVE_EXAM_PACKAGE, onGetQuantitativeExamPackage);
  yield takeEvery(SUBMIT_QUANTITATIVE_SINGLE_ANS, onAddQuantitativeSingleAns);
  yield takeEvery(SUBMIT_QUANTITATIVE_FULL_ANS, onAddQuantitativeFullAns);
  yield takeEvery(GET_QUANTITATIVE_RESULT, onGetQuantitativeResult);
}

export default QuantitativeQuesSaga;
