import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DashboardContentBody from "./dashboardPart/DashboardContentBody";
import GmatResult from "./dashboardPart/GmatResult";
import MockInterview from "./dashboardPart/MockInterview";
import StudentRasult from "./dashboardPart/StudentRasult";
const DashboardBody2 = () => {
  const [profileInfo, setProfileInfo] = useState(true);
  const { profile } = useSelector((state) => ({
    profile: state.Profile,
  }));
  const { loading, data } = profile;
  return (
    <div>
      <div className="dashboard-new-main-area-wrap">
        <Container>
          <div className="dashboard-new-inner-area-wrap">
            {profileInfo ? (
              <div className="user-profile-info-and-offer-area">
                <div className="user-profile-info-wrap">
                  <p>
                    Update your profile to get exciting offers.{" "}
                    <Link to="/profile">
                      <b>Update Profile</b>
                    </Link>
                    Your profile current completion rate{" "}
                    {data?.getData?.percentage} %
                  </p>
                  <button onClick={() => setProfileInfo(false)}>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.99935 12.8327C10.2077 12.8327 12.8327 10.2077 12.8327 6.99935C12.8327 3.79102 10.2077 1.16602 6.99935 1.16602C3.79102 1.16602 1.16602 3.79102 1.16602 6.99935C1.16602 10.2077 3.79102 12.8327 6.99935 12.8327Z"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.34961 8.65128L8.65128 5.34961"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.65128 8.65128L5.34961 5.34961"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            <MockInterview />
            <DashboardContentBody />
            <StudentRasult />
            <GmatResult />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default DashboardBody2;
