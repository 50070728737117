import React from "react";
import { Container } from "react-bootstrap";

const SingleAnalyticalQues = ({
  handleChangeMcq,
  questions,
  questionNumber,
}) => {
  console.log("questions", questions);
  return (
    <div>
      <div className="view-all-single-package-main-wrap">
        <Container>
          <div className="quantitative-reasoning-question">
            <div>
              <div className="ques-header-serial">
                {/* <h6 style={{ position: "initial" }}>1</h6> */}
                {/* <h5 className="fw-bold">Description</h5> */}
              </div>
              <div className="verbal-ques-description"></div>
            </div>
            <div>
              <div className="ques-header-question-part">
                <h5 className="fw-bold">Question</h5>
              </div>
              <div className="verbal-ques-description">
                <p>{questions?.questionTitle}</p>

                <textarea
                  style={{ height: "300px", width: "100%", padding: "10px" }}
                  className="mt-3"
                  onChange={(e) =>
                    handleChangeMcq({
                      e,
                      data: questions,
                      number: questionNumber,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SingleAnalyticalQues;
