import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import Loader from "../../../components/Atoms/Loader";
import {
  cancelExam,
  getGmatSinglePackage,
} from "../../../store/GMAT/Analytical/action";
import { cancelQuantitativeExam } from "../../../store/GMAT/Quantitative/action";

const QuantitativeInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { authToken, cancelExamLoading, totalQuantitativeQues } = useSelector(
    (state) => ({
      authToken: state.Login.token,
      cancelExamLoading: state.Login.cancelExamLoading,
      totalQuantitativeQues:
        state.QuantitativeQuestionReducer.totalQuantitativeQues,
    })
  );
  useEffect(() => {
    dispatch(getGmatSinglePackage(authToken, id));
  }, [id]);

  useEffect(() => {
    dispatch(cancelExam(authToken));
    dispatch(cancelQuantitativeExam());
  }, []);
  return (
    <div>
      {cancelExamLoading ? (
        <Loader />
      ) : (
        <div className="view-all-single-package-main-wrap">
          <Container>
            <div className="text-center">
              <h5>THE GMAT TEST</h5>
              <h4 className="fw-bold mt-3">
                Instruction for Quantitative Reasoning
              </h4>

              {/* <span>60 minutes | 6 questions</span> */}
              <p className="mt-3">
                There are Two types of questions in the Quantitative Reasoning
              </p>
            </div>
            <div>
              <Row>
                <Col md={6}>
                  <div className="mt-4 p-5">
                    <h5 className="fw-bold"> Data sufficiency </h5>
                    <p className="mt-3">
                      In the Data Sufficiency section, you will be presented
                      with a question followed by two statements labeled (1) and
                      (2). The statements provide information that may or may
                      not be sufficient to answer the question. you will then be
                      required to evaluate each statement independently and
                      determine whether the information provided is sufficient
                      to solve the problem.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mt-4 p-5">
                    <h5 className="fw-bold"> Problem Solving</h5>
                    <p className="mt-3">
                      In the Problem Solving section, you will be presented with
                      multiple-choice questions that require the application of
                      mathematical concepts, reasoning, and problem-solving
                      skills. The questions cover a wide range of topics,
                      including arithmetic, algebra, geometry, and data
                      analysis.
                    </p>
                  </div>
                </Col>
                <div className="text-center quantitative-start-exam-btn">
                  <Button color="danger" outline>
                    {" "}
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={() =>
                      history.push(
                        `/gmat-service/Quantitative/data-sufficiency/${id}`
                      )
                    }
                  >
                    Start Exam
                  </Button>
                </div>
              </Row>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default QuantitativeInfo;
