import {
  CLEAR_VERBAL_DATA,
  GET_VERBAL_EXAM_PACKAGE,
  GET_VERBAL_EXAM_PACKAGE_FAIL,
  GET_VERBAL_EXAM_PACKAGE_SUCCESS,
  GET_VERBAL_QUES,
  GET_VERBAL_QUES_FAIL,
  GET_VERBAL_QUES_SUCCESS,
  GET_VERBAL_RESULT,
  GET_VERBAL_RESULT_FAIL,
  GET_VERBAL_RESULT_SUCCESS,
  STORE_VERBAL_QUES,
  SUBMIT_VERBAL_FULL_ANS,
  SUBMIT_VERBAL_FULL_ANS_FAIL,
  SUBMIT_VERBAL_FULL_ANS_SUCCESS,
  SUBMIT_VERBAL_SINGLE_ANS,
  SUBMIT_VERBAL_SINGLE_ANS_FAIL,
  SUBMIT_VERBAL_SINGLE_ANS_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  loading: true,
  error: "",
  data: [],
  adding: false,
  verbalQues: [],
  verbalQuesLoading: true,
  verbalExamPackage: [],
  verbalResult: [],
  verbalExamPackageLoading: false,
  ans: [],
  verBalIndex: 0,
  readingComprehension: 0,
  sentenceEquivalence: 0,
  textComprehension: 0,
  totalVerbalQuestion: 0,
  verbalResultLoading: false,
  singleAns: {},
};

const verbalQuestionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VERBAL_QUES:
      return {
        ...state,
        verbalQuesLoading: true,
      };
    case GET_VERBAL_QUES_SUCCESS:
      return {
        ...state,
        verbalQuesLoading: false,
        verbalQues: action.payload?.values,
      };
    case GET_VERBAL_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        verbalQuesLoading: false,
      };
    case GET_VERBAL_EXAM_PACKAGE:
      return {
        ...state,
        verbalExamPackageLoading: true,
      };
    case GET_VERBAL_EXAM_PACKAGE_SUCCESS:
      return {
        ...state,
        verbalExamPackageLoading: false,
        verbalExamPackage: action.payload,
      };
    case GET_VERBAL_EXAM_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        verbalExamPackageLoading: false,
      };

    case GET_VERBAL_RESULT:
      return {
        ...state,
        verbalResultLoading: true,
      };
    case GET_VERBAL_RESULT_SUCCESS:
      return {
        ...state,
        verbalResultLoading: false,
        verbalResult: action.payload?.values,
      };
    case GET_VERBAL_RESULT_FAIL:
      return {
        ...state,
        error: action.payload,
        verbalResultLoading: false,
      };
    case SUBMIT_VERBAL_SINGLE_ANS:
      return {
        ...state,
        adding: true,
      };

    case SUBMIT_VERBAL_SINGLE_ANS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        verBalIndex: state.verBalIndex + 1,
        adding: false,
      };

    case SUBMIT_VERBAL_SINGLE_ANS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        adding: false,
      };
    case SUBMIT_VERBAL_FULL_ANS:
      return {
        ...state,
        adding: true,
      };

    case SUBMIT_VERBAL_FULL_ANS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        adding: false,
      };

    case SUBMIT_VERBAL_FULL_ANS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        adding: false,
      };

    case CLEAR_VERBAL_DATA:
      return {
        ...state,
        verBalIndex: 0,
      };
    case STORE_VERBAL_QUES:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };

    default:
      return state;
  }
};

export default verbalQuestionReducer;
