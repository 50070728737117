import CryptoJS from "crypto-js";

const secret = "&#hRx_fRonTenD_APp________________mAAc____@*&";

const createHash = (text) => {
  return CryptoJS.AES.encrypt(JSON.stringify(text), secret).toString();
};

const removeHash = (text = "") => {
  if (!text) return "";
  const bytes = CryptoJS.AES.decrypt(text, secret);
  try {
    const texts = bytes.toString(CryptoJS.enc.Utf8);
    return texts.slice(1, -1);
  } catch (err) {
    return "";
  }
};

export { createHash, removeHash };
