import {
  CLEAR_IR_DATA,
  GET_IR_EXAM_PACKAGE,
  GET_IR_EXAM_PACKAGE_FAIL,
  GET_IR_EXAM_PACKAGE_SUCCESS,
  GET_IR_QUES,
  GET_IR_QUES_FAIL,
  GET_IR_QUES_SUCCESS,
  GET_IR_RESULT,
  GET_IR_RESULT_FAIL,
  GET_IR_RESULT_SUCCESS,
  STORE_IR_QUES,
  SUBMIT_IR_FULL_ANS,
  SUBMIT_IR_FULL_ANS_FAIL,
  SUBMIT_IR_FULL_ANS_SUCCESS,
  SUBMIT_IR_SINGLE_ANS,
  SUBMIT_IR_SINGLE_ANS_FAIL,
  SUBMIT_IR_SINGLE_ANS_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  loading: true,
  error: "",
  data: [],
  adding: false,
  IRQues: [],
  IRQuesLoading: true,
  IRExamPackage: [],
  IRExamPackageLoading: false,
  IRresult: [],
  IRResultLoading: false,
  singleAns: {},
  currentIndex: 1,
  tableAnalysisPartOne: 0,
  tableAnalysisPartTwo: 0,
  twoPartAnalysisOne: 0,
  twoPartAnalysisTwo: 0,
  GraphicalInterpretation: 0,
  multiSource: 0,
  totalIrQues: 0,
  section: 1,
  answerResponse: 1,
  currentLevel: 3,
};

const IRQuesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_IR_QUES:
      return {
        ...state,
        IRQuesLoading: true,
      };
    case GET_IR_QUES_SUCCESS:
      return {
        ...state,
        IRQuesLoading: false,
        IRQues: action.payload?.values,
      };
    case GET_IR_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        IRQuesLoading: false,
      };
    case GET_IR_EXAM_PACKAGE:
      return {
        ...state,
        IRExamPackageLoading: true,
      };
    case GET_IR_EXAM_PACKAGE_SUCCESS:
      return {
        ...state,
        IRExamPackageLoading: false,
        IRExamPackage: action.payload,
      };
    case GET_IR_EXAM_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        IRExamPackageLoading: false,
      };
    case SUBMIT_IR_SINGLE_ANS:
      return {
        ...state,
        adding: true,
      };

    case SUBMIT_IR_SINGLE_ANS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        adding: false,
      };

    case SUBMIT_IR_SINGLE_ANS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        adding: false,
      };
    case SUBMIT_IR_FULL_ANS:
      return {
        ...state,
        adding: true,
      };

    case SUBMIT_IR_FULL_ANS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        adding: false,
      };

    case SUBMIT_IR_FULL_ANS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        adding: false,
      };

    case GET_IR_RESULT:
      return {
        ...state,
        IRResultLoading: true,
      };
    case GET_IR_RESULT_SUCCESS:
      return {
        ...state,
        IRResultLoading: false,
        IRresult: action.payload,
      };
    case GET_IR_RESULT_FAIL:
      return {
        ...state,
        error: action.payload,
        IRResultLoading: false,
      };

    case CLEAR_IR_DATA:
      return {
        ...state,
        currentIndex: 1,
        section: 1,
        answerResponse: 1,
        currentLevel: 3,
      };
    case STORE_IR_QUES:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };

    default:
      return state;
  }
};

export default IRQuesReducer;
