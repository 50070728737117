/* eslint-disable eqeqeq */
import { Box } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import packageProfile from "../../../assets/images/Frame.svg";

import { useEffect } from "react";
import { Button } from "reactstrap";
import Loader from "../../../components/Atoms/Loader";
import { imageBaseUrl } from "../../../helpers/api_helper";
import { cancelExam } from "../../../store/GMAT/Analytical/action";
import { getQuantitativeResult } from "../../../store/GMAT/Quantitative/action";
import "../ResultPage.scss";
import packageImage from "../img/01-3.png";
// import ViewListeningResult from "./ViewListening/ViewListening";

// const score = [
//   9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1, 0,
// ];
const imageHelper = (data) => {
  switch (data?.toLowerCase()?.trim()) {
    case "reading":
      return "reading.svg";
    case "speaking":
      return "speaking.svg";
    case "writing":
      return "writing.svg";
    case "listening":
      return "listening.svg";
    default:
      return "reading.svg";
  }
};
let quant = {
  51: 97,
  50: 87,
  49: 73,
  48: 65,
  47: 57,
  46: 53,
  45: 50,
  44: 44,
  43: 41,
  42: 36,
  41: 34,
  40: 32,
  39: 28,
  38: 27,
  37: 25,
  36: 22,
  35: 20,
  34: 19,
  33: 17,
  32: 15,
  31: 14,
  30: 13,
  29: 11,
  28: 11,
  27: 9,
  26: 8,
  25: 7,
  24: 7,
  23: 6,
  22: 5,
  21: 5,
  20: 4,
  19: 4,
  18: 4,
  17: 3,
  16: 3,
  15: 3,
  14: 2,
  13: 2,
  12: 2,
  11: 1,
  10: 1,
  9: 1,
  8: 1,
  7: 1,
  6: 0,
  5: 0,
  4: 0,
  3: 0,
  2: 0,
  1: 0,
};

const QuantitativeResult = ({ history }) => {
  const { id, packageId, type } = useParams();
  const [detailShow, setDetailShow] = useState(false);
  const dispatch = useDispatch();
  const {
    authtoken,
    QuantitativeResult,
    QuantitativeResultLoading,
    QuantitativeExamPackage,
    singlePackage,
    profile,
  } = useSelector((state) => ({
    authtoken: state.Login.token,

    QuantitativeExamPackage:
      state.QuantitativeQuestionReducer.QuantitativeExamPackage,
    QuantitativeResult: state.QuantitativeQuestionReducer.QuantitativeResult,
    QuantitativeResultLoading:
      state.QuantitativeQuestionReducer.QuantitativeResultLoading,
    singlePackage: state.PackageReducer.singlePackage?.singlePackage,
    profile: state.Profile?.data,
  }));

  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return packageImage;
  };
  const getPhotoUrl1 = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return packageProfile;
  };
  const ExamId = localStorage.getItem("quanExamId");
  const gmatPackageId = localStorage.getItem("gmatPackageId");
  useEffect(() => {
    if (ExamId) {
      dispatch(getQuantitativeResult(authtoken, ExamId));
    }
  }, []);
  useEffect(() => {
    dispatch(cancelExam(authtoken));
  }, []);
  return (
    <React.Fragment>
      <div className="result-main-area">
        <Container className="g-0">
          <div className="result-inner-area">
            {/* <button onClick={() => scroll('target1')}>go</button> */}
            <h3>
              <span onClick={() => history.goBack()}>
                <i className="bx bx-arrow-back"></i>
              </span>
              Your Result
            </h3>
            {QuantitativeResultLoading ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="result-main-area-inner-box">
                <React.Fragment>
                  <h4>Quantitative Exam Result</h4>
                  <div className="course-result--profile-area first-result-box">
                    <Row>
                      <Col lg={3}>
                        <div
                          className="exem-course-result-package rounded-5 m-0"
                          style={{
                            boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.08)",
                            overflow: "hidden",
                          }}
                        >
                          <Card className="course-result--profile-box rounded-5 m-0">
                            <div
                              className="card-img-top"
                              style={{
                                backgroundImage: `url(${getPhotoUrl(
                                  singlePackage?.photo
                                )})`,
                              }}
                            ></div>

                            <Card.Body
                              style={{
                                borderRadius: "0 0 10px 10px",
                                overflow: "hidden",
                              }}
                            >
                              <div className="course-review-area-exam-page d-flex">
                                <Box
                                  component="fieldset"
                                  borderColor="transparent"
                                >
                                  <span>
                                    <Rating
                                      name="simple-controlled"
                                      precision={0.1}
                                      defaultValue={
                                        singlePackage?.aveRatings || 0
                                      }
                                      readOnly
                                    />
                                  </span>
                                </Box>
                                <span style={{ color: "black" }}></span>
                              </div>
                              <Card.Title
                                className="course-review-area-exam-course-title"
                                style={{
                                  fontSize: "18px",
                                }}
                              >
                                {singlePackage?.packageName}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </div>
                      </Col>
                      <Col lg={9}>
                        <div className="exam-result-profile-feedback-view ps-3">
                          <div className="exam-result-profile---content">
                            <img
                              className="rounded-circle result-profile-page"
                              src={getPhotoUrl1(
                                profile?.getData?.getProfile?.photo
                              )}
                              alt=""
                            />

                            <span>
                              {profile?.getData?.getProfile?.fullName}
                            </span>
                          </div>
                          <div
                            className={
                              type === "writing"
                                ? "d-flex justify-content-center text-center gap-5 border-bottom py-4"
                                : "exam-result-feedback---content"
                            }
                          >
                            {type === "writing" ? null : (
                              <div className="exam-result-feedback---item">
                                <p>Mark</p>
                                <h6>
                                  {QuantitativeResult?.values?.mark?.toFixed(2)}
                                  /51
                                </h6>
                              </div>
                            )}
                            <div className="exam-result-feedback---item">
                              <p>Percentile</p>
                              <h6>
                                {
                                  quant[
                                    Math.ceil(QuantitativeResult?.values?.mark)
                                  ]
                                }{" "}
                                %
                              </h6>
                            </div>
                            <div className="exam-result-feedback---item">
                              {/* <p>Time Spent</p> */}
                              {/* <h6>{results?.timeTaken || 0} min</h6> */}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="exam-ques-your-position">
                          {/* <p>Review Brand Score</p>
                      <ul>
                        {score.map((list, idx, item) => {
                          return (
                            <li
                              key={idx}
                              className={
                                handleActiveClass(list, idx, item) === list
                                  ? 'your-exam-answer'
                                  : ''
                              }
                            >
                              {list}
                            </li>
                          );
                        })}
                      </ul> */}
                        </div>
                      </Col>
                    </Row>
                    <div className="pt-2 d-flex align-items-center">
                      {/* <img
                        src={require(`../img/${imageHelper(type)}`).default}
                        alt="set"
                        width={"40px"}
                      /> */}
                      {/* Set : <strong>{results?.setId?.setName}</strong> */}
                    </div>
                  </div>
                  <h4>Your answers keys</h4>
                </React.Fragment>

                <div>
                  {QuantitativeResultLoading ? (
                    <div className="text-center">
                      <Loader />
                    </div>
                  ) : (
                    <div className="course-result--profile-area">
                      <Table borderless hover responsive="md">
                        <thead>
                          <tr className="text-center">
                            <th>
                              <span>Question no</span>
                            </th>
                            <th>
                              <span>Right answer</span>
                            </th>
                            <th>
                              <span>Your answer</span>
                            </th>
                            <th>
                              <span>Level</span>
                            </th>
                            <th>
                              <span>Status</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {QuantitativeResult?.values?.answer?.map(
                            (ans, idx) => (
                              <tr>
                                <td>
                                  <span className="ques-id-serial text-center">
                                    {ans?.serial}
                                  </span>
                                </td>
                                <td>{ans?.rightAnswer[0]}</td>
                                <td>{ans?.answer}</td>
                                <td>
                                  {ans?.difficultyLevel === 1
                                    ? "Easy"
                                    : ans?.difficultyLevel === 2
                                    ? "Medium"
                                    : "Hard"}
                                </td>
                                <td>
                                  {" "}
                                  {ans.mark === 1 ? (
                                    <span className="correct-ans">
                                      <i className="bx bx-check"></i>
                                    </span>
                                  ) : (
                                    <span className="false-ans">
                                      <i className="bx bx-x"></i>
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  {/* <h4 id="target1" className="mt-5">
                    Review your answers and explanation
                  </h4> */}
                  <div
                    className="text-center"
                    onClick={() =>
                      history.push(`/gmat-service/${gmatPackageId}`)
                    }
                  >
                    <Button color="primary" outline>
                      Go to package
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(QuantitativeResult);
