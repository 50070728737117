import { call, put, takeEvery } from "redux-saga/effects";
import { getData, patchData } from "../../../helpers/backend_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  getIRExamPackageFail,
  getIRExamPackageSuccess,
  getIRQuesFail,
  getIRQuesSuccess,
  getIRResultFail,
  getIRResultSuccess,
  storeIRData,
  submitIRFullAnsFail,
  submitIRFullAnsSuccess,
  submitIRSingleAnsFail,
  submitIRSingleAnsSuccess,
} from "./action";
import {
  GET_IR_EXAM_PACKAGE,
  GET_IR_QUES,
  GET_IR_RESULT,
  SUBMIT_IR_FULL_ANS,
  SUBMIT_IR_SINGLE_ANS,
} from "./actionType";

function* onGetIRQues({
  payload: { token, Section, difficultyLevel, PackageId, option },
}) {
  try {
    let totalTableAnalysisPartOne = option?.tableAnalysisPartOne;
    let totalTableAnalysisPartTwo = option?.tableAnalysisPartTwo;
    let totalTwoPartAnalysisOne = option?.twoPartAnalysisOne;
    let totalTwoPartAnalysisTwo = option?.twoPartAnalysisTwo;
    let totalGraphicalInterpretation = option?.GraphicalInterpretation;
    let totalMultiSource = option?.multiSource;
    let sectionNum = Section;

    if (option?.isNext === true) {
      totalTableAnalysisPartOne =
        totalTableAnalysisPartOne > 0 ? totalTableAnalysisPartOne - 1 : 0;

      if (totalTableAnalysisPartOne < 1 && option?.tableAnalysisPartOne < 1) {
        sectionNum = 2;
        totalTableAnalysisPartTwo--;
      }
      if (totalTableAnalysisPartTwo < 1 && option?.tableAnalysisPartTwo < 1) {
        sectionNum = 3;
        totalTwoPartAnalysisOne--;
      }
      if (totalTwoPartAnalysisOne < 1 && option?.twoPartAnalysisOne < 1) {
        sectionNum = 4;
        totalTwoPartAnalysisTwo--;
      }
      if (totalTwoPartAnalysisTwo < 1 && option?.twoPartAnalysisTwo < 1) {
        sectionNum = 5;
        totalGraphicalInterpretation--;
      }

      if (
        totalGraphicalInterpretation < 1 &&
        option?.GraphicalInterpretation < 1
      ) {
        sectionNum = 6;
        totalMultiSource--;
      }
      yield put(storeIRData("currentIndex", option?.currentIndex + 1));
    }

    // Calculation for difficulty level

    let difficultValue;
    if (option?.currentLevel === 1 && option?.answerResponse === 1) {
      difficultValue = 2;
    }
    if (option?.currentLevel === 1 && option?.answerResponse === 0) {
      difficultValue = 1;
    }
    if (option?.currentLevel === 2 && option?.answerResponse === 1) {
      difficultValue = 3;
    }
    if (option?.currentLevel === 2 && option?.answerResponse === 0) {
      difficultValue = 1;
    }
    if (option?.currentLevel === 3 && option?.answerResponse === 1) {
      difficultValue = 3;
    }
    if (option?.currentLevel === 3 && option?.answerResponse === 0) {
      difficultValue = 2;
    }

    const url = `package/get-integrated-question/${sectionNum}/${difficultValue}/${option?.id}`;
    const response = yield call(getData, url, token);
    yield put(storeIRData("tableAnalysisPartOne", totalTableAnalysisPartOne));
    yield put(storeIRData("tableAnalysisPartTwo", totalTableAnalysisPartTwo));
    yield put(storeIRData("twoPartAnalysisOne", totalTwoPartAnalysisOne));

    yield put(storeIRData("twoPartAnalysisTwo", totalTwoPartAnalysisTwo));
    yield put(
      storeIRData("GraphicalInterpretation", totalGraphicalInterpretation)
    );
    yield put(storeIRData("multiSource", totalMultiSource));
    yield put(storeIRData("section", sectionNum));
    yield put(storeIRData("singleAns", {}));

    if (response?.status === "success") {
      yield put(getIRQuesSuccess(response?.getData));
    }
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getIRQuesFail(message));
    toaster("error", message);
  }
}

function* onGetIRExamPackage({ payload: { token, PackageId } }) {
  try {
    const url = `/package/start-integrated-exam/${PackageId}`;
    const response = yield call(getData, url, token);
    yield put(getIRExamPackageSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getIRExamPackageFail(message));
    toaster("error", message);
  }
}
function* onAddIRSingleAns({ payload: { obj, authToken, option } }) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, obj);
  console.log("option", option);
  try {
    const url = `package/integrated-answer-one-by-one/${option?.examId}/1/${option?.questionId}`;
    const response = yield call(patchData, url, obj, authToken);
    if (response?.status === "succes") {
      yield put(submitIRSingleAnsSuccess(response?.message));
      yield put(storeIRData("answerResponse", response?.answerResponse));
      yield put(storeIRData("currentLevel", obj?.difficultyLevel));
    }

    // toaster("success", `FAQ type added Successfully`);
    // history.push("/faqType");
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(submitIRSingleAnsFail(message));
  }
}
function* onAddIRFullAns({ payload: { obj, authToken, option } }) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, obj);
  try {
    const url = `package/integrated-answer-submit/${option?.examPackageId}`;
    const response = yield call(patchData, url, obj, authToken);
    yield put(submitIRFullAnsSuccess(response?.message));
    option?.setEndExam(false);
    option?.history.push("/Ir-result");
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(submitIRFullAnsFail(message));
  }
}

function* onGetIRResult({ payload: { token, examId } }) {
  try {
    const url = `package/integrated-exam-result/${examId}`;
    const response = yield call(getData, url, token);
    yield put(getIRResultSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getIRResultFail(message));
    toaster("error", message);
  }
}
function* IRSaga() {
  yield takeEvery(GET_IR_QUES, onGetIRQues);
  yield takeEvery(GET_IR_EXAM_PACKAGE, onGetIRExamPackage);
  yield takeEvery(SUBMIT_IR_SINGLE_ANS, onAddIRSingleAns);

  yield takeEvery(SUBMIT_IR_FULL_ANS, onAddIRFullAns);
  yield takeEvery(GET_IR_RESULT, onGetIRResult);
}

export default IRSaga;
