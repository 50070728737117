import {
  CLEAR_IR_DATA,
  GET_IR_EXAM_PACKAGE,
  GET_IR_EXAM_PACKAGE_FAIL,
  GET_IR_EXAM_PACKAGE_SUCCESS,
  GET_IR_QUES,
  GET_IR_QUES_FAIL,
  GET_IR_QUES_SUCCESS,
  GET_IR_RESULT,
  GET_IR_RESULT_FAIL,
  GET_IR_RESULT_SUCCESS,
  STORE_IR_QUES,
  SUBMIT_IR_FULL_ANS,
  SUBMIT_IR_FULL_ANS_FAIL,
  SUBMIT_IR_FULL_ANS_SUCCESS,
  SUBMIT_IR_SINGLE_ANS,
  SUBMIT_IR_SINGLE_ANS_FAIL,
  SUBMIT_IR_SINGLE_ANS_SUCCESS,
} from "./actionType";

export const getIRQues = (
  token,
  Section,
  difficultyLevel,
  PackageId,
  option
) => {
  return {
    type: GET_IR_QUES,
    payload: { token, Section, difficultyLevel, PackageId, option },
  };
};

export const getIRQuesSuccess = (values) => {
  return {
    type: GET_IR_QUES_SUCCESS,
    payload: { values },
  };
};

export const getIRQuesFail = (message) => {
  return {
    type: GET_IR_QUES_FAIL,
    payload: { message },
  };
};

export const getIRExamPackage = (token, PackageId) => {
  return {
    type: GET_IR_EXAM_PACKAGE,
    payload: { token, PackageId },
  };
};

export const getIRExamPackageSuccess = (values) => {
  return {
    type: GET_IR_EXAM_PACKAGE_SUCCESS,
    payload: { values },
  };
};

export const getIRExamPackageFail = (message) => {
  return {
    type: GET_IR_EXAM_PACKAGE_FAIL,
    payload: { message },
  };
};

export const submitIRSingleAns = (obj, authToken, option) => ({
  type: SUBMIT_IR_SINGLE_ANS,
  payload: { obj, authToken, option },
});

export const submitIRSingleAnsSuccess = (data) => ({
  type: SUBMIT_IR_SINGLE_ANS_SUCCESS,
  payload: { data },
});

export const submitIRSingleAnsFail = (data) => ({
  type: SUBMIT_IR_SINGLE_ANS_FAIL,
  payload: { data },
});
export const submitIRFullAns = (obj, authToken, option) => ({
  type: SUBMIT_IR_FULL_ANS,
  payload: { obj, authToken, option },
});

export const submitIRFullAnsSuccess = (data) => ({
  type: SUBMIT_IR_FULL_ANS_SUCCESS,
  payload: { data },
});

export const submitIRFullAnsFail = (data) => ({
  type: SUBMIT_IR_FULL_ANS_FAIL,
  payload: { data },
});

export const getIRResult = (token, examId) => {
  return {
    type: GET_IR_RESULT,
    payload: { token, examId },
  };
};

export const getIRResultSuccess = (values) => {
  return {
    type: GET_IR_RESULT_SUCCESS,
    payload: { values },
  };
};

export const getIRResultFail = (message) => {
  return {
    type: GET_IR_RESULT_FAIL,
    payload: { message },
  };
};

export const clearIrData = () => {
  return {
    type: CLEAR_IR_DATA,
    payload: {},
  };
};

export const storeIRData = (name, data) => ({
  type: STORE_IR_QUES,
  payload: { name, data },
});
