import {
  CANCEL_QUANTITATIVE_EXAM,
  GET_QUANTITATIVE_EXAM_PACKAGE,
  GET_QUANTITATIVE_EXAM_PACKAGE_FAIL,
  GET_QUANTITATIVE_EXAM_PACKAGE_SUCCESS,
  GET_QUANTITATIVE_QUES,
  GET_QUANTITATIVE_QUES_FAIL,
  GET_QUANTITATIVE_QUES_SUCCESS,
  GET_QUANTITATIVE_RESULT,
  GET_QUANTITATIVE_RESULT_FAIL,
  GET_QUANTITATIVE_RESULT_SUCCESS,
  STORE_QUANTITATIVE_QUES,
  SUBMIT_QUANTITATIVE_FULL_ANS,
  SUBMIT_QUANTITATIVE_FULL_ANS_FAIL,
  SUBMIT_QUANTITATIVE_FULL_ANS_SUCCESS,
  SUBMIT_QUANTITATIVE_SINGLE_ANS,
  SUBMIT_QUANTITATIVE_SINGLE_ANS_FAIL,
  SUBMIT_QUANTITATIVE_SINGLE_ANS_SUCCESS,
} from "./actionType";

export const getQuantitativeQues = (
  token,
  Section,
  difficultyLevel,
  PackageId
) => {
  return {
    type: GET_QUANTITATIVE_QUES,
    payload: { token, Section, difficultyLevel, PackageId },
  };
};

export const getQuantitativeQuesSuccess = (values) => {
  return {
    type: GET_QUANTITATIVE_QUES_SUCCESS,
    payload: { values },
  };
};

export const getQuantitativeQuesFail = (message) => {
  return {
    type: GET_QUANTITATIVE_QUES_FAIL,
    payload: { message },
  };
};

export const getQuantitativeExamPackage = (token, PackageId) => {
  return {
    type: GET_QUANTITATIVE_EXAM_PACKAGE,
    payload: { token, PackageId },
  };
};

export const getQuantitativeExamPackageSuccess = (values) => {
  return {
    type: GET_QUANTITATIVE_EXAM_PACKAGE_SUCCESS,
    payload: { values },
  };
};

export const getQuantitativeExamPackageFail = (message) => {
  return {
    type: GET_QUANTITATIVE_EXAM_PACKAGE_FAIL,
    payload: { message },
  };
};

export const submitQuantitativeSingleAns = (obj, authToken, option) => ({
  type: SUBMIT_QUANTITATIVE_SINGLE_ANS,
  payload: { obj, authToken, option },
});

export const submitQuantitativeSingleAnsSuccess = (data) => ({
  type: SUBMIT_QUANTITATIVE_SINGLE_ANS_SUCCESS,
  payload: { data },
});

export const submitQuantitativeSingleAnsFail = (data) => ({
  type: SUBMIT_QUANTITATIVE_SINGLE_ANS_FAIL,
  payload: { data },
});
export const submitQuantitativeFullAns = (obj, authToken, option) => ({
  type: SUBMIT_QUANTITATIVE_FULL_ANS,
  payload: { obj, authToken, option },
});

export const submitQuantitativeFullAnsSuccess = (data) => ({
  type: SUBMIT_QUANTITATIVE_FULL_ANS_SUCCESS,
  payload: { data },
});

export const submitQuantitativeFullAnsFail = (data) => ({
  type: SUBMIT_QUANTITATIVE_FULL_ANS_FAIL,
  payload: { data },
});

export const getQuantitativeResult = (token, examId) => {
  return {
    type: GET_QUANTITATIVE_RESULT,
    payload: { token, examId },
  };
};

export const getQuantitativeResultSuccess = (values) => {
  return {
    type: GET_QUANTITATIVE_RESULT_SUCCESS,
    payload: { values },
  };
};

export const getQuantitativeResultFail = (message) => {
  return {
    type: GET_QUANTITATIVE_RESULT_FAIL,
    payload: { message },
  };
};
export const cancelQuantitativeExam = () => {
  return {
    type: CANCEL_QUANTITATIVE_EXAM,
    payload: {},
  };
};

export const storeQuantitativeData = (name, data) => ({
  type: STORE_QUANTITATIVE_QUES,
  payload: { name, data },
});
