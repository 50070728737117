import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import {
  cancelExam,
  getGmatSinglePackage,
} from "../../../store/GMAT/Analytical/action";

const AnalyticalInfo = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => ({
    authToken: state.Login.token,
  }));
  useEffect(() => {
    dispatch(getGmatSinglePackage(authToken, id));
  }, [id]);
  useEffect(() => {
    dispatch(cancelExam(authToken));
  }, []);
  return (
    <div>
      <div className="view-all-single-package-main-wrap">
        <Container>
          <div className="text-center">
            <h5>THE GMAT TEST</h5>
            <h4 className="fw-bold mt-3">Instruction for Analytical Writing</h4>

            {/* <span>5 minutes | 10 questions</span> */}
            <p className="mt-3">
              There are one type of Analytical Writing question;{" "}
            </p>
          </div>
          <div>
            <Row>
              <Col md={8}>
                <div className="mt-4 p-5">
                  <h5 className="fw-bold"> Argument Problem</h5>
                  <p className="mt-3">
                    In the Argument Problem, you will be presented with a given
                    argument, typically a short passage, and are tasked with
                    critically analyzing the argument and providing a
                    well-structured response. The argument may present a logical
                    flaw, unsupported assumptions, weak evidence, or other
                    weaknesses that can be identified and addressed in the
                    response.
                  </p>
                </div>
              </Col>
              <Col md={4}></Col>

              <div className="text-center quantitative-start-exam-btn">
                <Button color="danger" outline>
                  {" "}
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() =>
                    history.push(`/gmat-service/analytical/exam/${id}`)
                  }
                >
                  Start Exam
                </Button>
              </div>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AnalyticalInfo;
