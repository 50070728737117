import React, { useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import Timer from "react-compound-timer/build";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useParams, withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import arrowRight from "../../../assets/images/Icon/arrow-ios-right.svg";
import time from "../../../assets/images/Icon/clock.svg";
import keypad from "../../../assets/images/Icon/keypad.svg";
import papar from "../../../assets/images/Icon/paper-plane.svg";
import submit from "../../../assets/images/submit.svg";
import { toaster } from "../../../helpers/custom/Toast";

import { getIRQues, submitIRFullAns } from "../../../store/GMAT/IR/action";

// import submitPopup from "./img/popupIamge.png";

const IrFooter = ({ history, handleScroll }) => {
  const [timeValue, setTimeValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const { id } = useParams();
  // const [isRender, setIsRender] = useState(false);
  const dispatch = useDispatch();
  // const [isSubmit, setIsSubmit] = useState(false);
  const [endExam, setEndExam] = useState(false);
  const totalQuestion = 5;
  const {
    currentIndex,
    tableAnalysisPartOne,
    tableAnalysisPartTwo,
    twoPartAnalysisOne,
    twoPartAnalysisTwo,
    GraphicalInterpretation,
    multiSource,
    IRExamPackage,
    authToken,
    singleAns,
    section,
    adding,
    IRQuesLoading,
    totalIrQues,
    answerResponse,
    currentLevel,
  } = useSelector((state) => ({
    authToken: state.Login.token,
    singleAns: state.IRQuesReducer.singleAns,
    tableAnalysisPartOne: state.IRQuesReducer.tableAnalysisPartOne,
    tableAnalysisPartTwo: state.IRQuesReducer.tableAnalysisPartTwo,
    twoPartAnalysisOne: state.IRQuesReducer.twoPartAnalysisOne,
    twoPartAnalysisTwo: state.IRQuesReducer.twoPartAnalysisTwo,
    GraphicalInterpretation: state.IRQuesReducer.GraphicalInterpretation,
    multiSource: state.IRQuesReducer.multiSource,
    currentIndex: state.IRQuesReducer.currentIndex,
    IRExamPackage: state.IRQuesReducer.IRExamPackage,
    IRQuesLoading: state.IRQuesReducer.IRQuesLoading,
    adding: state.IRQuesReducer.adding,
    section: state.IRQuesReducer.section,
    totalIrQues: state.IRQuesReducer.totalIrQues,
    answerResponse: state.IRQuesReducer.answerResponse,
    currentLevel: state.IRQuesReducer.currentLevel,
  }));

  const onOpenModal = () => setOpen(true);
  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal = () => setOpen(false);
  const onCloseModal2 = () => setOpen2(false);
  const [submitted, setSubmitted] = useState(false);

  const handleNext = () => {
    const option = {
      tableAnalysisPartOne,
      tableAnalysisPartTwo,
      twoPartAnalysisOne,
      twoPartAnalysisTwo,
      GraphicalInterpretation,
      multiSource,
      section,

      isNext: true,
      currentIndex,
      id,
      answerResponse,
      currentLevel,
    };

    dispatch(getIRQues(authToken, section, answerResponse, "", option));
  };

  useBeforeunload((event) => {
    event.preventDefault();
  });

  window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    e.returnValue = "";
  });

  const checkpoints = [
    {
      time: 10000 * 60,
      callback: () => {
        toaster("warning", "You have 10min remaining");
      },
    },
    {
      time: 20000 * 60,
      callback: () => {
        toaster("warning", "You have 20min remaining");
      },
    },
    {
      time: 0,
      callback: () => {
        toaster("success", "Your time is up! \n Submitting The answer");
        // submit();
        // history.goBack();
      },
    },
  ];
  const spendTime = Math.round((60000 * 60 - timeValue) / 60000);

  window.addEventListener("unload", (event) => {
    //call function to save you state in API or save in localStore
    console.log("before reload");
  });
  const handleFullAns = () => {
    const option = {
      examPackageId: IRExamPackage?.values,
      seFullAns: true,
      setEndExam,
      history,
    };
    let obj = {
      examStatus: 3,
    };
    dispatch(submitIRFullAns(obj, authToken, option));
  };
  const handleSubmit = () => {
    if (Object.keys(singleAns).length === 0) {
      toaster("warning", "Please select the answer first");
    } else {
      setEndExam(true);
    }
  };
  return (
    <div className="exam-ques-footer-main-wrap">
      {/* <PromptMessage
        dependent={isRouteChange}
        header={"Attention!! You are about to leave the exam."}
        content={
          "Are you sure you want to leave? You will not be able to give exam again."
        }
        submit={options2}
      /> */}
      <div className="ques-footer-top">
        <div className="exam-f-left">
          <h5>Question {currentIndex}</h5>
        </div>
        <div className="exam-f-right">
          <div className="exam-h-marks exam-h-t-right-button">
            {/* {currentIndex > 0 ? (
              <button
                className="btn  me-2"
                style={{ backgroundColor: "#fff", color: "#000" }}
                onClick={handleBack}
              >
                <i className="fa fa-angle-left"></i> Previous
              </button>
            ) : null} */}
            {totalIrQues !== currentIndex ? (
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleNext}
                disabled={Object.keys(singleAns).length === 0 || IRQuesLoading}
              >
                {IRQuesLoading ? "Submitting" : "Next"}{" "}
                <img src={arrowRight} alt="" />
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="ques-footer-bottom">
        <div
          className="exam-f-left"
          style={{ cursor: "pointer" }}
          onClick={onOpenModal2}
        >
          <React.Fragment>
            <img src={keypad} alt="question" />
            {/* <button className="btn questionPallet" type="submit">
              Question Palette
            </button> */}
          </React.Fragment>
        </div>
        <div className="exam-f-right">
          {false ? (
            "0:0"
          ) : (
            <div className="exam-h-marks">
              <h4>
                <img src={time} alt="" />
                <Timer
                  initialTime={30000 * 60}
                  checkpoints={checkpoints}
                  direction="backward"
                >
                  {({ getTime }) => (
                    <React.Fragment>
                      {setTimeValue(getTime())}
                      <Timer.Minutes />:
                      <Timer.Seconds />
                    </React.Fragment>
                  )}
                </Timer>
              </h4>
            </div>
          )}
        </div>{" "}
        <div className="exam-f-right">
          <div className="exam-h-right-button">
            {/* <button onClick={onOpenModal}>
              <button >Open modal</button>
              <img src={map} alt="" /> Review Answer
            </button> */}
            <Modal
              open={submitted}
              onClose={() => setSubmitted(false)}
              classNames={{
                modal: "submitExamModal",
              }}
              center
            >
              <div className="ques-answer-modal-container">
                {/* <img src={submitPopup} alt="" /> */}
                <h6>
                  <b>Want to submit?</b>
                </h6>
                <Row>
                  <Col lg={6}>
                    <div className="popup-answer-modal-single item-1 ">
                      <button onClick={() => setSubmitted(false)}>
                        Cancel
                      </button>
                    </div>
                  </Col>{" "}
                  <Col lg={6}>
                    {/* <div className="popup-answer-modal-single item-2">
                      <button disabled={adding} onClick={submitAnswer}>
                      
                        {adding ? "Submitting..." : "Submit and review "}
                      </button>
                    </div> */}
                  </Col>
                </Row>
              </div>
            </Modal>
            <button onClick={() => handleSubmit()}>
              <img src={papar} alt="" /> Submit
            </button>
          </div>
        </div>
        {/* <Modal open={open} onClose={onCloseModal} center>
          <div className="ques-popup-answer-wrap text-center">
            <h4>Review your answers</h4>
            <p>
              You can not change any answers from here. Just review your
              answers.
            </p>
          </div>
          <div className="d-flex flex-wrap mt-3">
            {ansData.map((data, key) => (
              <div className="border p-2" style={{ width: "24%" }} key={key}>
                <p>
                  {data.questionNumber}:{" "}
                  {data?.answer?.length > 1
                    ? data?.answer?.map((data) => `${data},`)
                    : data?.answer?.[0]}
                </p>
              </div>
            ))}
          </div>
        </Modal> */}
        {/* <Modal
          open={open2}
          onClose={onCloseModal2}
          center
          classNames={{
            overlay: "question-palette-overlay",
            modal: "question-palette-modal",
          }}
        >
          <div className="ques-answer-modal-container">
            <h3>Question Palette</h3>
            <p>
              You can not change any answers from here. Just review your
              answers.
            </p>
            <div className="answer-ques-list-color">
              <div className="answered-ques" style={{ fontSize: "14px" }}>
                <span
                  style={{
                    backgroundColor: "#34C38F",
                    width: "15px",
                    height: "15px",
                  }}
                ></span>
                Answered
              </div>
              <div className="unanswered-ques" style={{ fontSize: "14px" }}>
                <span
                  style={{
                    backgroundColor: "#F46A6A",
                    width: "15px",
                    height: "15px",
                  }}
                ></span>
                Unanswered
              </div>
            </div>
            <div className="ques-list-modal">
              <ul>
                {ansData.map((ques, i) => (
                  <li
                    key={i}
                    className="answer-single-question-palette"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleScroll(
                        `question${ques?.questionNumber}`,
                        ques?.questionNumber
                      );
                      onCloseModal2();
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: ques?.answer?.length
                          ? "#34C38F"
                          : "#F46A6A",
                      }}
                    >
                      {ques.questionNumber}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Modal> */}
        <Modal
          open={endExam}
          classNames={{
            overlay: "customOverlay",
            modal: "Xam-Confirm-Modal-main-wrap",
          }}
          center
        >
          <div className="text-center">
            <img src={submit} alt="" />
            <p className="mt-4 mb-4">
              Please make sure you have answered all the questions before
              submitting your response.
            </p>
          </div>
          <div className="text-center ">
            <Button
              color="primary"
              outline
              className="me-3"
              onClick={() => setEndExam(false)}
            >
              {" "}
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => handleFullAns()}
              disabled={adding}
              //   onClick={() => history.push(`/gmat-service/analytical/exam`)}
            >
              {adding ? "Submitting" : "Submit"}
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default withRouter(IrFooter);
