import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { Button } from "reactstrap";
import submit from "../../assets/images/submit.svg";
const Submit = () => {
  const [startXam, setStartXam] = useState(true);
  return (
    <div>
      <Modal
        open={startXam}
        classNames={{
          overlay: "customOverlay",
          modal: "Xam-Confirm-Modal-main-wrap",
        }}
        center
      >
        <div className="text-center">
          <img src={submit} alt="" />
          <p className="mt-4 mb-4">
            Please make sure you have answered all the questions before
            submitting your response.
          </p>
        </div>
        <div className="text-center ">
          <Button color="primary" outline className="me-3">
            {" "}
            Recheck
          </Button>
          <Button
            color="primary"
            //   onClick={() => history.push(`/gmat-service/analytical/exam`)}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Submit;
