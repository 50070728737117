import _clone from "lodash/clone";
import _escapeRegExp from "lodash/escapeRegExp";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import Loader from "../../../components/Atoms/Loader";
import "./Quantitative.css";
const QuantitativeQuesList = ({
  handleChangeMcq,
  questions,
  questionNumber,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const {
    authtoken,
    QuantitativeQues,
    QuantitativeQuesLoading,
    singleAns,
    totalDataSufficiency,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    QuantitativeQues: state.QuantitativeQuestionReducer.QuantitativeQues,
    QuantitativeQuesLoading:
      state.QuantitativeQuestionReducer.QuantitativeQuesLoading,
    singleAns: state.QuantitativeQuestionReducer.singleAns,
    totalDataSufficiency:
      state.QuantitativeQuestionReducer.totalDataSufficiency,
  }));
  console.log("singleAns", singleAns);
  console.log("QuantitativeQues", QuantitativeQues);

  function handleToggle(e) {
    console.log("sd", e.target.value);
    // setIsChecked(!isChecked);
  }
  const checkImage = (value) => {
    const tags = value?.match(/img@&#\[(.*?)\]#&@img+/gi) || [];
    if (tags.length === 1) {
      return true;
    } else {
      return false;
    }
  };

  const getImageUrl = (value) => {
    let displayText = _clone(value);
    const tags = value?.match(/img@&#\[(.*?)\]#&@img+/gi) || [];
    if (tags?.length) {
      tags.forEach((myTag, idx) => {
        const tagData = myTag?.slice(7, -7);
        displayText = displayText?.replace(
          new RegExp(_escapeRegExp(myTag), "gi"),
          tagData
        );
      });
    }
    return displayText;
  };
  console.log("questionNumber", questionNumber);
  return (
    <div>
      <div>
        <div className="view-all-single-package-main-wrap">
          {QuantitativeQuesLoading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : (
            <Container>
              <h5 className="fw-bold">Quantitative Reasoning</h5>
              <p className="mt-3">
                {questions?.values?.section === 1
                  ? "Data sufficiency"
                  : "Problem Solving"}
              </p>

              <div className="quantitative-reasoning-question">
                <div className="quantitative-single-ques-info">
                  <p className="quantitative-single-ques-serial">
                    {questionNumber}
                  </p>
                  <p className="quantitative-single-ques fw-bold">
                    {questions?.values?.q}
                  </p>
                </div>
                {questions?.values?.image ? (
                  <div className="quan-ques-image">
                    <img src={questions?.values?.image} alt="" />
                  </div>
                ) : (
                  ""
                )}

                <div className="quantitative-single-ques-options">
                  {questions?.values?.options?.map((data, idx) => (
                    <>
                      <div className="quantitative-single-ques-single-option">
                        <input
                          type="radio"
                          name={`question${questions?.values?.questionNumber}`}
                          id={`exampleRadios${questionNumber + idx}`}
                          checked={data === singleAns?.answer}
                          value={data}
                          onChange={(e) =>
                            handleChangeMcq({
                              e,
                              data: questions?.values,
                              number: questionNumber,
                            })
                          }
                        />
                         {" "}
                        <label htmlFor={`exampleRadios${questionNumber + idx}`}>
                          {checkImage(data) ? (
                            <img
                              style={{
                                height: "35px",
                                objectFit: "cover",
                              }}
                              src={getImageUrl(data)}
                              alt="img"
                            />
                          ) : (
                            data
                          )}
                        </label>
                        <br />
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuantitativeQuesList;
