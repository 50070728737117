export const GET_QUANTITATIVE_QUES = "GET_QUANTITATIVE_QUES";
export const GET_QUANTITATIVE_QUES_SUCCESS = "GET_QUANTITATIVE_QUES_SUCCESS";
export const GET_QUANTITATIVE_QUES_FAIL = "GET_QUANTITATIVE_QUES_FAIL";

export const GET_QUANTITATIVE_EXAM_PACKAGE = "GET_QUANTITATIVE_EXAM_PACKAGE";
export const GET_QUANTITATIVE_EXAM_PACKAGE_SUCCESS =
  "GET_QUANTITATIVE_EXAM_PACKAGE_SUCCESS";
export const GET_QUANTITATIVE_EXAM_PACKAGE_FAIL =
  "GET_QUANTITATIVE_EXAM_PACKAGE_FAIL";

export const SUBMIT_QUANTITATIVE_SINGLE_ANS = "SUBMIT_QUANTITATIVE_SINGLE_ANS";
export const SUBMIT_QUANTITATIVE_SINGLE_ANS_SUCCESS =
  "SUBMIT_QUANTITATIVE_SINGLE_ANS_SUCCESS";
export const SUBMIT_QUANTITATIVE_SINGLE_ANS_FAIL =
  "SUBMIT_QUANTITATIVE_SINGLE_ANS_FAIL";

export const SUBMIT_QUANTITATIVE_FULL_ANS = "SUBMIT_QUANTITATIVE_FULL_ANS";
export const SUBMIT_QUANTITATIVE_FULL_ANS_SUCCESS =
  "SUBMIT_QUANTITATIVE_FULL_ANS_SUCCESS";
export const SUBMIT_QUANTITATIVE_FULL_ANS_FAIL =
  "SUBMIT_QUANTITATIVE_FULL_ANS_FAIL";

export const GET_QUANTITATIVE_RESULT = "GET_QUANTITATIVE_RESULT";
export const GET_QUANTITATIVE_RESULT_SUCCESS =
  "GET_QUANTITATIVE_RESULT_SUCCESS";
export const GET_QUANTITATIVE_RESULT_FAIL = "GET_QUANTITATIVE_RESULT_FAIL";

export const CANCEL_QUANTITATIVE_EXAM = "CANCEL_QUANTITATIVE_EXAM";

export const STORE_QUANTITATIVE_QUES = "STORE_QUANTITATIVE_QUES";
