export const GET_ANALYTICAL_QUES = "GET_ANALYTICAL_QUES";
export const GET_ANALYTICAL_QUES_SUCCESS = "GET_ANALYTICAL_QUES_SUCCESS";
export const GET_ANALYTICAL_QUES_FAIL = "GET_ANALYTICAL_QUES_FAIL";

export const GET_ANALYTICAL_EXAM_PACKAGE = "GET_ANALYTICAL_EXAM_PACKAGE";
export const GET_ANALYTICAL_EXAM_PACKAGE_SUCCESS =
  "GET_ANALYTICAL_EXAM_PACKAGE_SUCCESS";
export const GET_ANALYTICAL_EXAM_PACKAGE_FAIL =
  "GET_ANALYTICAL_EXAM_PACKAGE_FAIL";

export const SUBMIT_ANALYTICAL_SINGLE_ANS = "SUBMIT_ANALYTICAL_SINGLE_ANS";
export const SUBMIT_ANALYTICAL_SINGLE_ANS_SUCCESS =
  "SUBMIT_ANALYTICAL_SINGLE_ANS_SUCCESS";
export const SUBMIT_ANALYTICAL_SINGLE_ANS_FAIL =
  "SUBMIT_ANALYTICAL_SINGLE_ANS_FAIL";

export const SUBMIT_ANALYTICAL_FULL_ANS = "SUBMIT_ANALYTICAL_FULL_ANS";
export const SUBMIT_ANALYTICAL_FULL_ANS_SUCCESS =
  "SUBMIT_ANALYTICAL_FULL_ANS_SUCCESS";
export const SUBMIT_ANALYTICAL_FULL_ANS_FAIL =
  "SUBMIT_ANALYTICAL_FULL_ANS_FAIL";

export const CANCEL_EXAM = "CANCEL_EXAM";
export const CANCEL_EXAM_SUCCESS = "CANCEL_EXAM_SUCCESS";
export const CANCEL_EXAM_FAIL = "CANCEL_EXAM_FAIL";

export const GET_GMAT_RESULT = "GET_GMAT_RESULT";
export const GET_GMAT_RESULT_SUCCESS = "GET_GMAT_RESULT_SUCCESS";
export const GET_GMAT_RESULT_FAIL = "GET_GMAT_RESULT_FAIL";

export const GET_SINGLE_GMAT_PACKAGE = "GET_SINGLE_GMAT_PACKAGE";
export const GET_SINGLE_GMAT_PACKAGE_SUCCESS =
  "GET_SINGLE_GMAT_PACKAGE_SUCCESS";
export const GET_SINGLE_GMAT_PACKAGE_FAIL = "GET_SINGLE_GMAT_PACKAGE_FAIL";

export const STORE_ANALYTICAL_QUES = "STORE_ANALYTICAL_QUES";
