import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Input, Row } from "reactstrap";
import { removeHash } from "../../../../helpers/custom/Hash";
import "./GraphicalPage.css";

const GraphicalPage = ({ question, handleChangeMcq }) => {
  console.log("question", question);
  const dispatch = useDispatch();
  let [answer, setAnswer] = useState([]);
  const replaceGap = (word, serial, options, question) => {
    const newWord = word.split("$gap$");
    // const [fist, ...rest] = newWord?.[0].split('<p>');

    return (
      <div className="">
        {newWord?.[0]}

        <Input
          type="select"
          name={`question.${serial}`}
          style={{
            border: "1px solid black",

            maxWidth: "120px",
            maxHeight: "32px",
            display: "inline-block",
            marginLeft: "10px",
            marginRight: "10px",
          }}
          //   questionInfo, number, option
          onChange={(e) => {
            let newAnswer = [...answer];
            newAnswer[serial] = e.target.value;
            setAnswer(newAnswer);
            handleChangeMcq({
              e,
              questionInfo: question,
              number: serial,
              option: options,
            });
          }}
          //   value={answer[serial] || ""}
        >
          <option value="">Select...</option>
          {options?.opt?.map((data, idx) => (
            <option key={idx} value={data}>
              {data}
            </option>
          ))}
        </Input>
        {newWord?.[newWord?.length - 1]}
      </div>
    );
  };

  return (
    <div>
      <div>
        <div className="view-all-single-package-main-wrap">
          <Container>
            <h5 className="fw-bold">Integrated Reasoning</h5>
            <p className="mt-3">Graphical Integration</p>

            <div className="two-part-analysis-question-passage">
              <Row>
                <Col md={6}>
                  <div className="mb-4">
                    <p className="mb-3">Description</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: question?.description
                          ? removeHash(question?.description)
                          : "",
                      }}
                    ></p>
                    <img
                      src={question?.photo}
                      style={{ height: "200px" }}
                      alt=""
                    />
                  </div>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <div>
                    <p
                      className="mb-4"
                      dangerouslySetInnerHTML={{
                        __html: question?.questionHeading
                          ? removeHash(question?.questionHeading)
                          : "",
                      }}
                    ></p>
                  </div>
                  <div>
                    {question?.options?.map((data, idx) => (
                      <p className="mb-4" key={idx}>
                        {replaceGap(data?.option, idx + 1, data, question)}
                      </p>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default GraphicalPage;
