import {
  CANCEL_EXAM,
  CANCEL_EXAM_FAIL,
  CANCEL_EXAM_SUCCESS,
  GET_ANALYTICAL_EXAM_PACKAGE,
  GET_ANALYTICAL_EXAM_PACKAGE_FAIL,
  GET_ANALYTICAL_EXAM_PACKAGE_SUCCESS,
  GET_ANALYTICAL_QUES,
  GET_ANALYTICAL_QUES_FAIL,
  GET_ANALYTICAL_QUES_SUCCESS,
  GET_GMAT_RESULT,
  GET_GMAT_RESULT_FAIL,
  GET_GMAT_RESULT_SUCCESS,
  GET_SINGLE_GMAT_PACKAGE,
  GET_SINGLE_GMAT_PACKAGE_FAIL,
  GET_SINGLE_GMAT_PACKAGE_SUCCESS,
  STORE_ANALYTICAL_QUES,
  SUBMIT_ANALYTICAL_FULL_ANS,
  SUBMIT_ANALYTICAL_FULL_ANS_FAIL,
  SUBMIT_ANALYTICAL_FULL_ANS_SUCCESS,
  SUBMIT_ANALYTICAL_SINGLE_ANS,
  SUBMIT_ANALYTICAL_SINGLE_ANS_FAIL,
  SUBMIT_ANALYTICAL_SINGLE_ANS_SUCCESS,
} from "./actionType";

export const getAnalyticalQues = (
  token,
  Section,
  difficultyLevel,
  PackageId
) => {
  return {
    type: GET_ANALYTICAL_QUES,
    payload: { token, Section, difficultyLevel, PackageId },
  };
};

export const getAnalyticalQuesSuccess = (values) => {
  return {
    type: GET_ANALYTICAL_QUES_SUCCESS,
    payload: { values },
  };
};

export const getAnalyticalQuesFail = (message) => {
  return {
    type: GET_ANALYTICAL_QUES_FAIL,
    payload: { message },
  };
};

export const getAnalyticalExamPackage = (token, PackageId) => {
  return {
    type: GET_ANALYTICAL_EXAM_PACKAGE,
    payload: { token, PackageId },
  };
};

export const getAnalyticalExamPackageSuccess = (values) => {
  return {
    type: GET_ANALYTICAL_EXAM_PACKAGE_SUCCESS,
    payload: { values },
  };
};

export const getAnalyticalExamPackageFail = (message) => {
  return {
    type: GET_ANALYTICAL_EXAM_PACKAGE_FAIL,
    payload: { message },
  };
};

export const submitAnalyticalSingleAns = (obj, authToken, option) => ({
  type: SUBMIT_ANALYTICAL_SINGLE_ANS,
  payload: { obj, authToken, option },
});

export const submitAnalyticalSingleAnsSuccess = (data) => ({
  type: SUBMIT_ANALYTICAL_SINGLE_ANS_SUCCESS,
  payload: { data },
});

export const submitAnalyticalSingleAnsFail = (data) => ({
  type: SUBMIT_ANALYTICAL_SINGLE_ANS_FAIL,
  payload: { data },
});
export const submitAnalyticalFullAns = (obj, authToken, option) => ({
  type: SUBMIT_ANALYTICAL_FULL_ANS,
  payload: { obj, authToken, option },
});

export const submitAnalyticalFullAnsSuccess = (data) => ({
  type: SUBMIT_ANALYTICAL_FULL_ANS_SUCCESS,
  payload: { data },
});

export const submitAnalyticalFullAnsFail = (data) => ({
  type: SUBMIT_ANALYTICAL_FULL_ANS_FAIL,
  payload: { data },
});

export const cancelExam = (token) => {
  return {
    type: CANCEL_EXAM,
    payload: { token },
  };
};

export const cancelExamSuccess = (values) => {
  return {
    type: CANCEL_EXAM_SUCCESS,
    payload: { values },
  };
};

export const cancelExamFail = (message) => {
  return {
    type: CANCEL_EXAM_FAIL,
    payload: { message },
  };
};

export const getGmatResult = (token) => {
  return {
    type: GET_GMAT_RESULT,
    payload: { token },
  };
};

export const getGmatResultSuccess = (values) => {
  return {
    type: GET_GMAT_RESULT_SUCCESS,
    payload: { values },
  };
};

export const getGmatResultFail = (message) => {
  return {
    type: GET_GMAT_RESULT_FAIL,
    payload: { message },
  };
};
export const getGmatSinglePackage = (token, packageId) => {
  return {
    type: GET_SINGLE_GMAT_PACKAGE,
    payload: { token, packageId },
  };
};

export const getGmatSinglePackageSuccess = (values) => {
  return {
    type: GET_SINGLE_GMAT_PACKAGE_SUCCESS,
    payload: { values },
  };
};

export const getGmatSinglePackageFail = (message) => {
  return {
    type: GET_SINGLE_GMAT_PACKAGE_FAIL,
    payload: { message },
  };
};

export const storeAnalyticalData = (name, data) => ({
  type: STORE_ANALYTICAL_QUES,
  payload: { name, data },
});
