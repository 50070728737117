import { call, put, takeEvery } from "redux-saga/effects";
import { getData, patchData } from "../../../helpers/backend_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  getVerbalExamPackageFail,
  getVerbalExamPackageSuccess,
  getVerbalQues,
  getVerbalQuesFail,
  getVerbalQuesSuccess,
  getVerbalResultFail,
  getVerbalResultSuccess,
  storeVerbalData,
  submitVerBalFullAns,
  submitVerBalFullAnsFail,
  submitVerBalFullAnsSuccess,
  submitVerbalSingleAnsFail,
  submitVerbalSingleAnsSuccess,
} from "./action";
import {
  GET_VERBAL_EXAM_PACKAGE,
  GET_VERBAL_QUES,
  GET_VERBAL_RESULT,
  SUBMIT_VERBAL_FULL_ANS,
  SUBMIT_VERBAL_SINGLE_ANS,
} from "./actionType";

function* onGetVerbalQues({
  payload: { token, Section, difficultyLevel, PackageId },
}) {
  try {
    const url = `package/get-verbal-question/${Section}/${difficultyLevel}/${PackageId}`;
    const response = yield call(getData, url, token);
    yield put(getVerbalQuesSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getVerbalQuesFail(message));
    toaster("error", message);
  }
}

function* onGetVerbalExamPackage({ payload: { token, PackageId } }) {
  try {
    const url = `package/start-verbal-exam/${PackageId}`;
    const response = yield call(getData, url, token);
    yield put(getVerbalExamPackageSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getVerbalExamPackageFail(message));
    toaster("error", message);
  }
}
function* onAddVerbalSingleAns({ payload: { obj, authToken, option } }) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, option);

  try {
    console.log("obj", obj);
    const url = `package/verbal-answer-one-by-one/${option?.examId}/${option?.sectionInfo}/${option?.questionId}`;
    const response = yield call(patchData, url, obj, authToken);
    console.log("response", response);
    if (response?.status === "succes") {
      yield put(storeVerbalData("singleAns", {}));

      let difficultValue;
      let answerCheck = response?.answerResponse;
      let difficultyCheck = response?.difficultyLevel;

      // Section for single question

      let totalReadingComprehension = option?.readingComprehension;
      let totalSentenceEquivalence = option?.sentenceEquivalence;
      let totalTextComprehension = option?.textComprehension;

      totalReadingComprehension =
        totalReadingComprehension > 0 ? totalReadingComprehension - 1 : 0;

      if (totalReadingComprehension < 1 && option?.readingComprehension < 1) {
        totalSentenceEquivalence = totalSentenceEquivalence - 1;
      }
      if (totalSentenceEquivalence < 1 && option?.sentenceEquivalence < 1) {
        totalTextComprehension = totalTextComprehension - 1;
      }
      yield put(
        storeVerbalData("readingComprehension", totalReadingComprehension)
      );
      yield put(storeVerbalData("textComprehension", totalTextComprehension));
      yield put(
        storeVerbalData("sentenceEquivalence", totalSentenceEquivalence)
      );
      let section =
        totalReadingComprehension > 0
          ? "1"
          : totalSentenceEquivalence > 0
          ? "2"
          : "3";

      // Difficulty level for single question
      console.log("option?.sectionInfo", option?.sectionInfo);

      if (option?.sectionInfo === 1 && totalReadingComprehension < 1) {
        difficultValue = 3;
      } else {
        console.log("kk", difficultyCheck, answerCheck);

        if (difficultyCheck === 1 && answerCheck === 1) {
          difficultValue = 2;
        }
        if (difficultyCheck === 1 && answerCheck === 0) {
          difficultValue = 1;
        }
        if (difficultyCheck === 2 && answerCheck === 1) {
          difficultValue = 3;
        }
        if (difficultyCheck === 2 && answerCheck === 0) {
          difficultValue = 1;
        }
        if (difficultyCheck === 3 && answerCheck === 1) {
          difficultValue = 3;
        }
        if (difficultyCheck === 3 && answerCheck === 0) {
          difficultValue = 2;
        }
      }

      yield put(submitVerbalSingleAnsSuccess(response?.message));

      if (totalReadingComprehension < 1) {
        console.log("section45", section);
        if (option?.seFullAns !== true) {
          yield put(
            getVerbalQues(authToken, section, difficultValue, option?.packageId)
          );
        }
      }

      if (option?.seFullAns === true) {
        yield put(submitVerBalFullAns({ examStatus: 3 }, authToken, option));
      }
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(submitVerbalSingleAnsFail(message));
  }
}
function* onAddVerbalFullAns({ payload: { obj, authToken, option } }) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, obj);
  try {
    const url = `package/verbal-answer-submit/${option?.examId}`;
    const response = yield call(patchData, url, obj, authToken);

    yield put(submitVerBalFullAnsSuccess(response?.message));
    yield put(storeVerbalData("verBalIndex", 0));
    option?.setEndExam(false);
    option?.history.push("/verbal-result");
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`;
    toaster("error", message);
    yield put(submitVerBalFullAnsFail(message));
  }
}

function* onGetVerbalResult({ payload: { token, examId } }) {
  try {
    const url = `package/verbal-exam-result/${examId}`;
    const response = yield call(getData, url, token);
    yield put(getVerbalResultSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getVerbalResultFail(message));
    toaster("error", message);
  }
}
function* VerbalQuesSaga() {
  yield takeEvery(GET_VERBAL_QUES, onGetVerbalQues);
  yield takeEvery(GET_VERBAL_EXAM_PACKAGE, onGetVerbalExamPackage);
  yield takeEvery(SUBMIT_VERBAL_SINGLE_ANS, onAddVerbalSingleAns);

  yield takeEvery(SUBMIT_VERBAL_FULL_ANS, onAddVerbalFullAns);
  yield takeEvery(GET_VERBAL_RESULT, onGetVerbalResult);
}

export default VerbalQuesSaga;
