import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useParams, withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import CardComponent from "../../components/Common/CardComponent";
import { toCapitalize } from "../../helpers/custom/capitalize";
import {
  cancelExam,
  getGmatSinglePackage,
} from "../../store/GMAT/Analytical/action";
import { clearIrData } from "../../store/GMAT/IR/action";
import { cancelQuantitativeExam } from "../../store/GMAT/Quantitative/action";
import { clearVerbalData } from "../../store/GMAT/Verbal/action";
const gmatService = [
  {
    name: "Quantitative Reasoning",
    value: 2,
  },
  {
    name: "Verbal Reasoning",
    value: 1,
  },
  {
    name: "Analytical Writing",
    value: 4,
  },
  {
    name: "Integrated Reasoning",
    value: 3,
  },
];

const imageHelper = (data) => {
  console.log("data", data);
  switch (data.name?.toLowerCase()?.trim()) {
    case "quantitative reasoning":
      return "QR.svg";
    case "verbal reasoning":
      return "verbal.svg";
    case "analytical writing":
      return "Aw.svg";
    case "integrated reasoning":
      return "IR.svg";
    default:
      return "verbal.svg";
  }
};
const GmatService = ({ userService, history }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  console.log("id", id);
  const { authToken } = useSelector((state) => ({
    authToken: state.Login.token,
  }));

  useEffect(() => {
    dispatch(getGmatSinglePackage(authToken, id));
  }, [id]);

  useEffect(() => {
    dispatch(cancelExam(authToken));
    dispatch(cancelQuantitativeExam());
    dispatch(clearVerbalData());
    dispatch(clearIrData());
  }, []);

  const [startXam, setStartXam] = useState(false);
  // console.log("courseType", courseId);
  localStorage.setItem("gmatPackageId", id);
  const handler = (data) => {
    switch (data?.name?.toLowerCase()?.trim()) {
      case "quantitative reasoning":
        history.push(`/gmat-service/Quantitative/info/${id}`);
        break;
      case "verbal reasoning":
        history.push(`/gmat-service/verbal/info/${id}`);
        break;
      case "analytical writing":
        history.push(`/gmat-service/analyticalInfo/info/${id}`);
        break;
      case "integrated reasoning":
        history.push(`/gmat-service/integrated-reasoning/info/${id}`);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    dispatch(cancelExam(authToken));
    dispatch(cancelQuantitativeExam());
  }, []);
  return (
    <React.Fragment>
      <div className="view-all-single-package-main-wrap">
        <Modal
          open={startXam}
          classNames={{
            overlay: "customOverlay",
            modal: "Xam-Confirm-Modal-main-wrap",
          }}
          center
        >
          <div className="start-xam-popup-inner-wrap">
            <div className="start-xam-pro-content-wrap">
              <div className="start-xam-popup-content text-center">
                <h6 className="">Instruction for Integrated Reasoning</h6>
                <p className="mt-4">5 minutes | 10 questions</p>
                <p>
                  There are three types of questions in the Integrated Reasoning
                  Critical Reasoning; Reading Comprehension; and Sentence
                  Correction.
                </p>
              </div>
            </div>
            <div className="popup-form-btn-container">
              <button
                onClick={() => setStartXam(false)}
                className="btn button-popup-background-1"
              >
                Cancel
              </button>

              <button
                //  disabled={profile?.examDone?.reading !== key}
                // onClick={() => history.push(`/reading-exam/${xam}/${packageId}`)}
                className="btn button-popup-background-2"
              >
                Start
              </button>
            </div>
          </div>
        </Modal>
        <Container>
          <div className="page-title-wrap">
            <div className="">
              <h4>Full Package Vo: 1</h4>
            </div>
            <div className="">
              {/* <span>Time Expire</span> */}
              {/* <span>30D : 24H : 58M : 56S</span> */}
            </div>
          </div>
          <div
            className="single-package-inner-main-wrap mx-auto"
            style={{ maxWidth: "80%" }}
          >
            <Row>
              {(gmatService || [])?.map((data, key) => (
                <Col lg={6} md={6} key={key}>
                  <div className="card-active-deactive-content">
                    <CardComponent className={"text-center py-4"}>
                      <div className="mx-auto mb-3">
                        <img
                          src={require(`./img/${imageHelper(data)}`).default}
                          alt="examImage"
                        />
                      </div>
                      <div>
                        <h5 className="mb-3">{toCapitalize(data.name)}</h5>

                        <button
                          className="btn btn-primary"
                          onClick={() => handler(data)}
                        >
                          View
                        </button>
                      </div>
                    </CardComponent>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(GmatService);
