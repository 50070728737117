import {
  CANCEL_QUANTITATIVE_EXAM,
  GET_QUANTITATIVE_EXAM_PACKAGE,
  GET_QUANTITATIVE_EXAM_PACKAGE_FAIL,
  GET_QUANTITATIVE_EXAM_PACKAGE_SUCCESS,
  GET_QUANTITATIVE_QUES,
  GET_QUANTITATIVE_QUES_FAIL,
  GET_QUANTITATIVE_QUES_SUCCESS,
  GET_QUANTITATIVE_RESULT,
  GET_QUANTITATIVE_RESULT_FAIL,
  GET_QUANTITATIVE_RESULT_SUCCESS,
  STORE_QUANTITATIVE_QUES,
  SUBMIT_QUANTITATIVE_FULL_ANS,
  SUBMIT_QUANTITATIVE_FULL_ANS_FAIL,
  SUBMIT_QUANTITATIVE_FULL_ANS_SUCCESS,
  SUBMIT_QUANTITATIVE_SINGLE_ANS,
  SUBMIT_QUANTITATIVE_SINGLE_ANS_FAIL,
  SUBMIT_QUANTITATIVE_SINGLE_ANS_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  loading: true,
  error: "",
  data: [],
  adding: false,
  QuantitativeQues: [],
  QuantitativeQuesLoading: false,
  QuantitativeExamPackage: [],
  QuantitativeExamPackageLoading: false,
  ans: [],
  QuantitativeResult: [],
  QuantitativeResultLoading: false,
  currentIndex: 0,
  totalDataSufficiency: 0,
  totalProblemSolving: 0,
  totalQuantitativeQues: 0,
  singleAns: {},
};

const QuantitativeQuestionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUANTITATIVE_QUES:
      return {
        ...state,
        QuantitativeQuesLoading: true,
      };
    case GET_QUANTITATIVE_QUES_SUCCESS:
      return {
        ...state,
        QuantitativeQuesLoading: false,
        QuantitativeQues: action.payload,
      };
    case GET_QUANTITATIVE_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        QuantitativeQuesLoading: false,
      };
    case GET_QUANTITATIVE_EXAM_PACKAGE:
      return {
        ...state,
        QuantitativeExamPackageLoading: true,
      };
    case GET_QUANTITATIVE_EXAM_PACKAGE_SUCCESS:
      return {
        ...state,
        QuantitativeExamPackageLoading: false,
        QuantitativeExamPackage: action.payload,
      };
    case GET_QUANTITATIVE_EXAM_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        QuantitativeExamPackageLoading: false,
      };

    case GET_QUANTITATIVE_RESULT:
      return {
        ...state,
        QuantitativeResultLoading: true,
      };
    case GET_QUANTITATIVE_RESULT_SUCCESS:
      return {
        ...state,
        QuantitativeResultLoading: false,
        QuantitativeResult: action.payload,
      };
    case GET_QUANTITATIVE_RESULT_FAIL:
      return {
        ...state,
        error: action.payload,
        QuantitativeResultLoading: false,
      };
    case SUBMIT_QUANTITATIVE_SINGLE_ANS:
      return {
        ...state,
        adding: true,
      };

    case SUBMIT_QUANTITATIVE_SINGLE_ANS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        adding: false,
      };

    case SUBMIT_QUANTITATIVE_SINGLE_ANS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        adding: false,
      };
    case SUBMIT_QUANTITATIVE_FULL_ANS:
      return {
        ...state,
        adding: true,
      };

    case SUBMIT_QUANTITATIVE_FULL_ANS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        adding: false,
      };

    case SUBMIT_QUANTITATIVE_FULL_ANS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        adding: false,
      };
    case CANCEL_QUANTITATIVE_EXAM:
      return {
        ...state,
        currentIndex: 0,
      };
    case STORE_QUANTITATIVE_QUES:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };

    default:
      return state;
  }
};

export default QuantitativeQuestionReducer;
