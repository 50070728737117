import React from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Row, Table } from "reactstrap";
import { removeHash } from "../../../../helpers/custom/Hash";
import "./TwoPartAnalysis.css";
const tableHead = ["Either day", "Neither day", "Writer"];

const TwoPartAnalysis = ({ question, handleChangeMcq, currentIndex }) => {
  const dispatch = useDispatch();
  const mainArray = question?.rowDataList;
  const subArrays = [];
  let columnLength = question?.columnNameList?.length;

  for (let i = 0; i < mainArray.length; i += columnLength) {
    const subArray = mainArray.slice(i, i + columnLength);
    subArrays.push(subArray);
  }

  console.log("IRQues", question);
  return (
    <div>
      <div>
        <div className="view-all-single-package-main-wrap">
          <Container>
            <h5 className="fw-bold">Integrated Reasoning</h5>
            <p className="mt-3">Two Part Analysis</p>

            <div className="two-part-analysis-question-passage">
              <Row>
                <Col md={6}>
                  <div className="mb-4">
                    <p className="mb-3">Description</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: question?.description
                          ? removeHash(question?.description)
                          : "",
                      }}
                    ></p>
                  </div>
                  <Row>
                    <Table bordered className="table-analysis-table-header">
                      <thead>
                        <tr className="text-center">
                          {question?.columnNameList?.map((data, idx) => (
                            <th>{data}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {subArrays?.map((row, idx) => (
                          <tr>
                            {row?.map((column, idx) => (
                              <td>{column}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <div>
                    <p className="mb-4">
                      Select writer who could be added to the schedule for
                      either day. Then select a writer who could be added to the
                      schedule for neither day. Make only two selection
                    </p>
                  </div>

                  <div className="two-part-analysis-table">
                    <Table>
                      <thead>
                        <tr>
                          {question?.answerOptions?.map((data, idx) => (
                            <th>{data}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {question?.options?.map((data, idx) => (
                          <tr>
                            {question?.section === 3 ? (
                              <>
                                {" "}
                                <td>
                                  <input
                                    type="radio"
                                    name={`question_${idx}`}
                                    Value="Either Day"
                                    onChange={(e) =>
                                      handleChangeMcq({
                                        e,
                                        questionInfo: question,
                                        number: idx + 1,
                                        option: data,
                                      })
                                    }
                                  ></input>
                                </td>
                                <td>
                                  {" "}
                                  <input
                                    type="radio"
                                    name={`question_${idx}`}
                                    value="Neither Day"
                                    onChange={(e) =>
                                      handleChangeMcq({
                                        e,
                                        questionInfo: question,
                                        number: idx + 1,
                                        option: data,
                                      })
                                    }
                                  ></input>
                                </td>
                              </>
                            ) : (
                              <>
                                {" "}
                                <td>
                                  <input
                                    type="radio"
                                    name={`question_${idx}`}
                                    Value="Organization A"
                                    onChange={(e) =>
                                      handleChangeMcq({
                                        e,
                                        questionInfo: question,
                                        number: idx + 1,
                                        option: data,
                                      })
                                    }
                                  ></input>
                                </td>
                                <td>
                                  {" "}
                                  <input
                                    type="radio"
                                    name={`question_${idx}`}
                                    value="Organization B"
                                    onChange={(e) =>
                                      handleChangeMcq({
                                        e,
                                        questionInfo: question,
                                        number: idx + 1,
                                        option: data,
                                      })
                                    }
                                  ></input>
                                </td>
                              </>
                            )}

                            <td>{data?.option}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default TwoPartAnalysis;
